import { useEffect, useState, useCallback } from "react";
import { axiosInstance } from "../../services/Axios";
import { calculateAndConvertTime } from "../../utils/TimeConversion";
import { get_visit } from "../../services/VisitRouter";


export function useFetchVisits(timeRange, page, size=null,title = "") {
    const [ visits, setVisits ] = useState([]);
    const [ totalVisits, setTotalVisits ] = useState(0);
    const [ visitsLoading, setVisitsLoading ] = useState(false);
    const [ visitsError, setVisitsError] = useState(null);
    const [ reloadFlag, setReloadFlag ] = useState(0);

    const reload = useCallback(() => {
        setReloadFlag((prev) => prev + 1); 
    }, []);

    const read_visits = async () => {
        try {
            setVisitsLoading(true);
            
            const payload = {
                from_time: calculateAndConvertTime(timeRange),
                to_time: calculateAndConvertTime("current"),
                sort: "-created_at",
                page: page,
                size: size ? size : 25,
            };
    
            
            if (title) {
                payload.title = title;
            }
    
        
            const response = await axiosInstance.get("/api/v1/visit", { params: payload });
            
            setTotalVisits(response.data["total"]);
            setVisits(response.data["items"]);
            setVisitsError(null);
        } catch (error) {
            setVisits([]);
            setTotalVisits(0);
            setVisitsError(error);
        } finally {
            setVisitsLoading(false);
        }
    };
    

    useEffect(() => {
        read_visits();
        }, [timeRange, page, title, reloadFlag]);

    return { visits, totalVisits, visitsLoading, visitsError, reload }
}

export function useFetchVisit(visitID) {
    const [ visit, setVisit ] = useState(null);
    const [ visitLoading, setVisitLoading ] = useState(false);
    const [ visitError, setVisitError ] = useState(null);
    const [ reloadFlag, setReloadFlag ] = useState(0);

    const fetch_visit = async () => {
        try {
            setVisitLoading(true);
            setVisit(null);
            const response = await get_visit(visitID);
            setVisit(response);
            setVisitError(null);
        } catch (error) {
            setVisitError(error);
            setVisit(null);
        } finally {
            setVisitLoading(false);
        }
    }

    const reload = useCallback(() => {
        setReloadFlag((prev) => prev + 1);
    }, []);

    useEffect(() => {
        fetch_visit()
    }, [visitID, reloadFlag])

    return { visit, visitLoading, visitError, reload }
}

