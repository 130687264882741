import React, { useEffect } from "react";
import "../styling/DefaultView.css";
import { useNavigate } from "react-router-dom";
import { Box, Button, Link, Typography, Divider  } from "@mui/joy";
import { authGuard } from "../services/Authentication";


function DefaultView() {
    const navigate = useNavigate();

    useEffect(() => {
        const checkUser = async () => {
            if (await authGuard()) {
                navigate('/new-visit');
            }
        }
        checkUser();
    }, [])

    const goSignIn = () => {
        navigate("/login");
    }

    const goSignUp = () => {
        navigate("/signup");
    }
    
    return (<Box sx={{ display: "flex", flexGrow: 1, height: "100%", justifyContent: "center", }}>
        <Box sx={(theme) => ({
                margin: '25px', borderRadius: '30px', display: "flex", flexDirection: "column", boxShadow: theme.shadow.md, backgroundColor: 'rgb(255, 255, 255, 50%)', [theme.breakpoints.up('sm')]: {
                    width: '30%'
                }, [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: '70%',
                    alignSelf: 'center',
                }, justifyContent: "space-between", gap: 2, alignItems: "center"
            })}>
            <Box
                sx={{display: "flex", justifyContent: "center",  alignItems: "center", width: "100%", mt:"5rem"}}
            >
                <img src="/assets/logo.png" alt='Logo' style={{maxWidth: "80%"}}/>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flexGrow: 1, 
                    gap: 2, 
                    width: "100%",
                }}
            >
                    <Typography
                    level="h1"
                    sx={{
                        
                        fontWeight: "600",
                    }}
                >
                    Welcome!
                </Typography>
                <Button
                    fullWidth
                    sx={{
                        backgroundColor: "var(--dark-blue-button)",
                        color: "white",
                        padding: "12px",
                        borderRadius: "8px",
                        fontSize: "1rem",
                        fontWeight: "600",
                        textTransform: "none",
                        maxWidth: "85%",
                        mb: 0,
                        "&:hover": { backgroundColor: "var(--dark-blue-button-hover)" },
                    }}
                    onClick={goSignIn}
                >
                    Sign in to your account
                </Button>

                <Divider sx={{ my: 0, color: "text.secondary", fontSize: "0.875rem" }}>OR</Divider>

                    <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                        padding: "12px",
                        backgroundColor: "var(--light-gray)",
                        borderRadius: "8px",
                        fontSize: "1rem",
                        fontWeight: "600",
                        textTransform: "none",
                        color: "var(--dark-blue-button)",
                        maxWidth: "85%",
                        border: "none",
                        mt: 0,
                    }}
                    onClick={goSignUp}
                >
                    Create new account
                </Button>

                <Typography
                    sx={{
                        textAlign: "center",
                        mt: 0,
                        color: "var(--dark-blue-button-hover)",
                        fontSize: "0.875rem",
                    }}
                >
                    Need help?{" "}
                    <Link
                            href="mailto:team@medxinsight.com"
                            underline="hover"
                            sx={{ color: "var(--main-blue)" }}
                        >
                            Contact us
                        </Link>
                </Typography>
            </Box>
        </Box>
    </Box>);
    }


export default DefaultView