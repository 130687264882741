import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { User, NotepadText, MessagesSquare, Timer, Speech } from 'lucide-react';

import React, { useContext, useEffect, useState, useRef, useCallback } from "react"
import { useParams, useOutletContext, useNavigate } from "react-router-dom"
import { useFetchVisit } from "../hooks/api/Visits";
import { CircularProgress, Typography, Sheet, Box, Button, Divider, Input } from "@mui/joy";
import { AlertContext } from "../context/AlertFlag";
import { convertTimeString } from '../utils/TimeConversion';
import { delete_visit, put_visit, regenerate_note } from "../services/VisitRouter";
import useDebounce from '../hooks/useDebounce';
import NavBlocker from '../components/modals/NavBlocker';
import { NoteTextComponent } from '../components/custom/NoteTextComponent';
import ConfirmDeleteVisitModal from '../components/modals/ConfirmDeleteNote';

function PastVisitsExpanded() {
    const { visitId } = useParams();
    const { addAlert } = useContext(AlertContext);
    const parentContext = useOutletContext();
    const navigate = useNavigate();
    const { visit, visitLoading, visitError, reload } = useFetchVisit(visitId);

    const [ triggerReload, setTriggerReload ] = useState(0);
    const [ title, setTitle ] = useState(null);
    const [ editingNote, setEditingNote ] = useState(false);
    const [ editingTranscript, setEditingTranscript ] = useState(false);
    const [ view, setView ] = useState("note");
    const [ deleteVisit, setDeleteVisit ] = useState(false);

    const noteRef = useRef(null);
    const transcriptRef = useRef(null);

    const chooseTranscript = () => {
        if (visit['user_edited_transcript']['value'].length <= 2) {
            return visit['transcription']['result']['transcript']
        } else {
            return visit['user_edited_transcript']['value']
        }
    }

    const handleCancelTranscript = () => {
        setEditingTranscript(false);

        if (transcriptRef.current) {
            transcriptRef.current.textContent = chooseTranscript()
        }
    }

    const handleSaveNote = async (text) => {
        const request_body = {
            "user_edited_result": {
                "value": text,
              },
              "title": visit.title,
              "user_edited_transcript": {
                  "value": transcriptRef.current.innerText.trim(),
              }
        }
        try {
            await put_visit(visitId, request_body);
            addAlert("Visit saved!", "success");
            reload();
        } catch (error) {
            addAlert(error.message, "danger");
        }
    }
    const handleDelete = async () => {
        try {
            await delete_visit(visitId);
            parentContext.reload();
            setTriggerReload((prev) => prev + 1);
            addAlert("Visit deleted!", "success");
            navigate('/past-visits');
        } catch (error) {
            addAlert(error.message, "danger");
        }
    }

    const handleRegenerateTranscript = async () => {
        setEditingTranscript(false);
        try {
            await handleSaveNote("");
            await regenerate_note(visitId);
            addAlert("Visit regeneration queued!", "success");
        } catch (error) {
            addAlert(error.message, "danger");
        }
    }

    const handleTitleChange = useCallback(async () => {
        if (title && visit) {
            const request_body = {
                "user_edited_result": {
                    "value": visit.user_edited_result.value,
                  },
                  "title": title,
                  "user_edited_transcript": {
                      "value": visit.user_edited_transcript.value,
                  }
            }
            try {
                await put_visit(visitId, request_body);
                setTriggerReload((prev) => prev + 1);
            } catch (error) {
                addAlert(error.message, "danger")
            }
        }
    }, [title, visit, visitId, put_visit])

    useDebounce(handleTitleChange, 500, [title])

    useEffect(() => {
        parentContext.reload();
    }, [triggerReload, put_visit])

    useEffect(() => {
        if (visitError) {
            addAlert(visitError.message, "danger");
        }
    }, [visitError])

    useEffect(() => {
        if (visit && visit.title) {
            setTitle(visit.title);
        }
    }, [visit])

    return <Sheet sx={{width: "100%", height: "100vh", overflow: "auto"}} key={visitId}>
        <NavBlocker dirty={editingNote || editingTranscript}/>
        <ConfirmDeleteVisitModal open={deleteVisit} on_close={() => {setDeleteVisit(false)}} delete_visit={handleDelete}/>
        {visit && <Box>
            <Box sx={{display: "flex", justifyContent: "space-between", pl: 2, pr: 4, paddingTop: 3.75 }}>
                <Box sx={{display: "flex", gap: 2, alignItems: "flex-end"}}>
                    <Input value={title} size="lg" variant="plain" onChange={(event) => {setTitle(event.target.value)}} startDecorator={<User />} sx={{ fontSize: "larger", fontWeight: "bold" }}/>
                </Box>
                <Box sx={{display: "flex", gap: 2 }}>
                    {/* <Button sx={{ display: "flex", width: "fit-content", whiteSpace: "nowrap" }} startDecorator={<CloudOutlinedIcon />}>
                        Sync to EMR
                    </Button> */}
                    <Button onClick={async () => {setDeleteVisit(true)}} sx={{ display: "flex", backgroundColor: "white", width: "fit-content" }} startDecorator={<DeleteOutlineIcon/>} variant="outlined" color="danger"> Delete </Button>
                </Box>
            </Box>
            <Box sx={{display: "flex", justifyContent: "space-between", px: 4, py: 1}}>
                <Box sx={{display: "flex", gap: 2, alignItems: "center"}}>
                    <Timer style={{ color: "var(--joy-palette-text-tertiary, var(--joy-palette-neutral-600, #555E68))"}}/>
                    <Typography level="body-sm"> Created at: {convertTimeString(visit.created_at)}</Typography>
                    <Typography level="body-sm"> Updated at: {convertTimeString(visit.updated_at)}</Typography>
                </Box>
                <Button sx={{ backgroundColor: "white", width: "fit-content", display: "flex" }} variant="plain" onClick={() => {
                        if (view === "note") {
                            setView("transcript");
                        } else {
                            setView("note");
                        }
                    }} startDecorator={view === "note" ? <Speech /> : <NotepadText />}> {view === "note" ? "Transcript" : "Note"} </Button>
            </Box>
            <Divider/>
            <Box sx={{ backgroundColor: "var(--joy-palette-neutral-50)" }}>
                {visit.generate_note ? <Box sx={{ m: 2, py: 2, border: "1px solid var(--joy-palette-divider)", backgroundColor: "white", borderRadius: "12px" }}>
                    <Box sx={{ display: view === "note" ? "block" : "none" }}>
                        <NoteTextComponent ref={noteRef} visit={visit} lift_editing={(value) => {setEditingNote(value)}} lift_save={handleSaveNote}/>
                    </Box>
                    <Box sx={{ display: view === "transcript" ? "block" : "none"}}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", py: 0.5, px: 2, gap: 1}}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <MessagesSquare style={{ color: "var(--main-blue)", height: "2.75em", width: "2.75em", paddingRight: 2}}/>
                                <Typography level="title-lg" sx={{pb: 0.4}}> Transcript </Typography>
                            </Box>
                            <Box sx={{ display: "flex", gap: 1 }}>
                                {editingTranscript && <Button sx={{ backgroundColor: "white", color: "gray" }} variant="plain" onClick={() => {
                                    handleCancelTranscript();
                                    setEditingTranscript(false);
                                }}>
                                    Cancel
                                </Button>}
                                <Button sx={{ backgroundColor: "white" }} variant="plain" onClick={ async () => {
                                    if (editingTranscript) {
                                        await handleRegenerateTranscript();
                                    } else {
                                        setEditingTranscript(true);
                                    }
                                }}>
                                    {editingTranscript ? "Regenerate" : "Edit"}
                                </Button>
                            </Box>
                        </Box>
                        <Divider sx={{ my: 1 }}/>
                        <Box sx={{whiteSpace: 'pre-wrap', p: 1, }}>
                            <Typography level="body-sm" sx={{ mt: 2, px: 2 }} ref={transcriptRef} contentEditable={editingTranscript} suppressContentEditableWarning={true}>
                                {chooseTranscript()}
                            </Typography>
                        </Box>
                    </Box>
                </Box> : <Box sx={{ display: "flex", height: "50vh", alignItems: "center", justifyContent: "center"}}>
                    <CircularProgress size="lg"/>
                </Box>}
            </Box>
        </Box>}
        {visitLoading && <Box sx={{ display: "flex", height: "70vh", alignItems: "center", justifyContent: "center"}}>
            <CircularProgress size="lg"/>
        </Box>}
    </Sheet>

}

export default PastVisitsExpanded;