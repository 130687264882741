export function formatNoteName(string) {
    switch(string) {
        case ("physio"): {
            return "Physiotherapy Note"
        } case ("athletic"): {
            return "Athletic Note"
        } case ("chiropractic"): {
            return "Chiropractic Note"
        } case ("sports_medicine"): {
            return "Sports Medical Note"
        } case ("sports_medicine_consult_letter"): {
            return "Sports Medical Consult Letter"
        } case ("radiology"): {
            return "Radiology"
        } case ("therapy_cbt_dbt"): {
            return "Therapy - CBT / DBT Note"
        } case ("therapy_family_remediation"): {
            return "Therapy - Family Mediation Note"
        } case ("therapy_marriage_counselling"): {
            return "Therapy - Partner Counselling Note"
        } case ("therapy_parenting_plan"): {
            return "Therapy - Parenting Plan Note"
        } case ("emergency_response"): {
            return "Emergency Response"
        } case ("therapy_note"): {
            return "Therapy Note"
        } case ("therapy_case_note"): {
            return "Therapy - Case Note"
        } case ("therapy_intake_assessment_adult"): {
            return "Therapy - Intake Assessment Adult"
        } case ("therapy_intake_assessment_child"): {
            return "Therapy - Intake Assessment Child"

        } default: {
            return capitalizeWords(string, "_")
        }
    }
}

export function formatNoteSections(string) {
    return capitalizeWords(string, "_");
}


export function capitalizeWords(sentence, factor) {
    const words = sentence.split(factor);
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(' ');
}

export function checkExistingTitles(string) {
    const regex = /\*\*([^*]+)\*\*/g;
    return regex.test(string);
}


export function formatPhoneNumber(phoneString) {
    if (!phoneString) {
        return phoneString;
    }

    let input = phoneString.replace(/\D/g, "");
    let proper_number = "";

    if (input.length >= 10) {
        proper_number += `(${input.substring(0 , 3)})-${input.substring(3 , 6)}-${input.substring(6, 10)}`;
    } else {
        proper_number = input;
    }
    return proper_number
}

export function grabAvatar(full_name) {
    if (full_name.includes(" ")) {
        const split_name = full_name.split(" ");
        return split_name[0].substring(0, 1).toUpperCase() + split_name[1].substring(0, 1).toUpperCase();
    } else {
        return full_name.substring(0, 1).toUpperCase();
    }
}

export function createNoteText(titles, noteBodyReferences) {
    try {
        let noteText = '';
        titles.forEach( (title, index) => {
            index === 0 ? noteText += `**${title}**\n` : noteText += `\n\n**${title}**\n `;
            noteText += noteBodyReferences[index].current.innerText;
        })
        return noteText;
    } catch (error) {
        throw new Error(error);
    }
}