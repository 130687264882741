import { useEffect } from 'react';

function useDebounce(callback, delay, dependencies) {
    useEffect(() => {
      const handler = setTimeout(() => {
        callback();
      }, delay);
  
      return () => clearTimeout(handler); // Cleanup on dependency change or unmount
    }, [...dependencies, delay]);
  }
  

export default useDebounce;
