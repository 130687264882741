import { convertTimeString } from "./TimeConversion";
import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer"
import { formatNoteName } from "./FormatNoteNameAndText";

export function downloadTextoTxt(soapNote, noteName, createdAt) {
    const blob = new Blob([`Visit at: ${convertTimeString(createdAt)} \n\n`, soapNote], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${noteName}.txt`;
    link.click();
    URL.revokeObjectURL(url);
}

Font.register({ family: 'Space Mono', fonts: [
    {
        src: "/fonts/SpaceMono.ttf",
    }, {
        src: "/fonts/SpaceMono.ttf",
        fontWeight: 'bold',
    }
]});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 20,
    },
    section: {
        margin: 10,
        padding: 10,
        fontSize: 12,
    },
    title: {
        fontSize: 18,
        marginBottom: 10,
        textAlign: 'center',
    },
    image: {
        marginVertical: 10,
        marginHorizontal: 100,
        width: 10,
        height: 10,
    },
    divider: {
        width: "100%",
        border: "1px solid #f1f3f4",
        marginTop: 15,
    },
    titleText: {
        fontFamily: 'Space Mono',
        fontWeight: 'bold',
        fontSize: 14,
        paddingTop: 5,
    },
    headerText: {
        fontFamily: 'Space Mono',
        fontWeight: 'bold',
        color: '#5774f0',
        paddingTop: 15,
        fontSize: 11,
    },
    normalText: {
        fontFamily: 'Space Mono',
        fontSize: 9,
    }
});

const RenderStyledNote = ({ soapNote }) => {
    // Regular expression to match the headers in the format **HEADER**
    const regex = /(\*\*[A-Z\s]+\*\*)/gi;

    // Function to choose styles based on the content of the string
    const getTextStyle = (str) => {
        const occurrences = (str.match(/\*\*/g) || []).length;
        if (occurrences >= 2) {
            return styles.headerText; // Style for headers
        }
        return styles.normalText; // Default style for normal text
    };

    function splitByTitles(input) {
        const regex = /(\*\*[^*]+\*\*)/g;
        const result = input.split(regex).filter(part => part.trim() !== '');
        return result;
    }

    // Split the text by the headers and include them in the output
    const textSplitByHeaders = splitByTitles(soapNote)
    
    return <View>
        {textSplitByHeaders.map((str, index) => (
            <Text key={index} style={getTextStyle(str)}>
                {str.replace(/\*\*/g, '')}
            </Text>
        ))}
    </View>

}


export const NoteToPDF = ({ note, title, createdBy, noteType }) => {
    let soapNote;

    if (note.user_edited_result.value) {
        soapNote = note.user_edited_result.value;
    } else {
        soapNote = note.generate_note.generated_result.value;
    }

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={{display: "flex", alignItems: "flex-end"}}>
                <Image style={{width: "40%", maxHeight: "2in"}} src="/assets/logo.png" />
            </View>
            <View style={{paddingTop: 15}}>
                <Text style={styles.normalText}>
                    {createdBy}
                </Text>
            </View>
            <View>
                <Text style={styles.titleText}>
                    {formatNoteName(noteType)}
                </Text>
                <Text style={styles.normalText}>
                    {convertTimeString(note.created_at)}
                </Text>
            </View>
            <View style={styles.divider}/>
            <RenderStyledNote soapNote={soapNote} />
            <View style={styles.divider}/>
        </Page>
    </Document>
}