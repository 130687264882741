import { getVisitCapacity } from "../services/VisitCapacity";


// Check status of all elements before 
export async function checkVisitAllowed(time, addAlert) {

    if (time >= 9000) {
        addAlert("You've hit the recording limit of 2 hours. Please hit 'complete' to save the note for your visit.", 'danger');
        throw new Error("Recording Limit reached")
    }

    const visitCapacity = await getVisitCapacity();
    if (!visitCapacity && !visitCapacity.can_create) {
        addAlert("You’ve reached the maximum number of daily visits for your subscription. Upgrade for more daily visits", 'danger');
        throw new Error("Visit max reached")
    }

}
