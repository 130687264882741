import { axiosInstance } from "./Axios";
import { getUserID } from "./Authentication";

export async function put_user_account(user_details) {
    try {
        const response = await axiosInstance.put(`/api/v1/user/${getUserID()}`, user_details)
        return response.data;
    } catch (error) {
        console.log(error)
        throw new Error(error);
    }
}

export async function get_user_account() {
    try {
        const response = await axiosInstance.get(`/api/v1/user/${getUserID()}`)
        return response.data
    } catch(error) {
        throw new Error(error);
    }
}