import React from "react";
import { formatNoteName } from "../../utils/FormatNoteNameAndText";
import { Chip, Typography } from "@mui/joy";
import { FileText } from "lucide-react";



export function NoteNameChip({ noteName, ...rest }) {
    return <Chip size="sm" variant="plain" startDecorator={<FileText style={{ width: '15px' }}/>} {...rest} >
        <Typography level="body-xs">
            {formatNoteName(noteName)}
        </Typography>
    </Chip>
}