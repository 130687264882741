import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip, Typography } from "@mui/joy";
import React, { useState, useEffect, useContext } from "react"
import { AlertContext } from '../../context/AlertFlag';
import { copyToClipboard } from '../../utils/Clipboard';
import { capitalizeWords } from '../../utils/FormatNoteNameAndText';

function NoteDisplayCard(props) {
    const { addAlert } = useContext(AlertContext);

    const [titles, setTitles] = useState([]);
    const [parts, setParts] = useState([]);
    const [editable, setEditable] = useState(false);
    

    useEffect( () => {
        setTitles(props.noteTitles);
        setParts(props.noteText);
        setEditable(props.editable);
    }, [props.editable, props.noteTitles])


    return <>
        {(titles.map((title, index) => {
            return <div key={`${title} ${index}`}>
             <div style={{marginBottom: '4px', display: "flex"}}>
                <Typography level="body-lg" sx={{alignSelf: "center", m: 0, color: 'var(--main-blue)', fontSize: '1.125rem'}}>
                    {capitalizeWords(title.toLowerCase(), ' ')}
                </Typography>
                {!editable && <Tooltip title="Copy section" size="sm">
                    <IconButton onClick={() => {
                        addAlert("Section Copied!", "success")
                        copyToClipboard(parts[index])
                        }} sx={{ width: "fit-content", alignSelf: "center"}}>
                        <ContentCopyIcon fontSize="lg"/>
                    </IconButton>
                </Tooltip>}
            </div>
            <Typography contentEditable={editable} ref={props.assignRefs[index]} suppressContentEditableWarning={true} level="body-sm">
                {`${parts[index]} \n\n`}
            </Typography>
            </div>
        })
        )}
    </>
}

export function formattedText(soapString, setTitles, setRefs, assignRefs, editable, setParts) {
    const text=`Ensure start ${soapString}`;
    let match;
    const titles = [];
    const regex = /\*\*([^*]+)\*\*/g;
    let parts = [];

    while ((match = regex.exec(text)) !== null) {
        titles.push(match[1]);
    }
    setTitles(titles);

    let lastIndex = 0;
    let refsIndex = 0;
    for (let i = 0; i < titles.length; i++) {
        const title = titles[i];
        const index = text.indexOf(`**${title}**`, lastIndex) - 1;
        if (index !== -1) {
            parts.push(text.substring(lastIndex, index).trim());
            setRefs(refsIndex);
            refsIndex += 1;
            lastIndex = index + title.length + 6; 
        }
    }

    // Add the last part of the text
    if (lastIndex < text.length) {
        parts.push(text.substring(lastIndex).trim());
        setRefs(refsIndex);
    }

    // Remove start
    parts = parts.slice(1);
    setParts(parts);

    return <NoteDisplayCard noteText={parts} noteTitles={titles} assignRefs={assignRefs} editable={editable}/>
}
