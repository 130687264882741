import React, { useEffect, useState, useContext } from 'react';
import MicrophoneContext from '../../../context/MicContext';
import { Select, Option, Box, Typography } from '@mui/joy';
import { Mic } from '@mui/icons-material';

const MicrophoneSelect = ({ updateRecordingStream, disabled }) => {
  const { selectedMic, setSelectedMic } = useContext(MicrophoneContext);
  const [ microphones, setMicrophones ] = useState([]);

  const set_default_mic = () => {
    try {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const mics = devices.filter((device) => device.kind === 'audioinput');
        setMicrophones(mics);
  
        if (mics.length > 0) {
          setSelectedMic(mics[0].deviceId); // Typically the first device is the default
        }
      });
    } catch (error) {
      console.log("Couldn't detect a microphone", "danger");
    }
  }

  useEffect(() => {
    set_default_mic();
  }, []);

  useEffect(() => {
    if (selectedMic) {
      navigator.mediaDevices
        .getUserMedia({
          audio: { deviceId: { exact: selectedMic } },
        })
        .then((stream) => {
          updateRecordingStream(stream); // Pass the new stream to the recorder
        })
        .catch((error) => {
          set_default_mic();
        });
    }
  }, [selectedMic]);

  const handleChange = (e, mic) => {
    setSelectedMic(mic);
  };

  return (
    <Box  sx={{width: {xs: "100%", sm: "25%"}}}>
      <Typography level="body-sm"> Audio Source </Typography>
      <Select value={selectedMic} onChange={handleChange} disabled={disabled} startDecorator={
          <Box sx={{display: "flex", alignItems: "center"}}>
              <Mic />
          </Box>
      }>
        <Option value="" disabled>
          Select a microphone
        </Option>
        {microphones.map((mic) => (
          <Option key={mic.deviceId} value={mic.deviceId}>
            {mic.label || `Microphone ${mic.deviceId}`}
          </Option>
        ))}
      </Select>
    </Box>
  );
};

export default MicrophoneSelect;
