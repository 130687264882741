// REAL LAYOUT
import React, { useEffect, useState } from 'react';
import '../App.css';
import { Outlet } from 'react-router-dom';
import MainLayout from './MainLayout';
import { Sheet } from '@mui/joy';
import { authGuard } from '../services/Authentication';
import SystemBanner from './SystemBanner';
import MobileMenuButton from './MenuButton';

function Layout() {
    const [visible, setVisible] = useState(false);
    const [systemBanner, setSystemBanner] = useState(false);

    useEffect(() => {
      const checkAuthState = async () => {
        setVisible(await authGuard())
      }
  
      // Define the event listener
      const handleStorageChange = (event) => {
        if (event.detail.key === 'token') { 
          checkAuthState();
        }
      };
      window.addEventListener('localStorageChange', handleStorageChange);
      checkAuthState();
  
      return () => {
        window.removeEventListener('localStorageChange', handleStorageChange);
      }
    }, [])
  
    return (
        <div style={{ backgroundImage: "unset", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", height: "100vh", overflow: "hidden"}}>
          <SystemBanner setVisible={setSystemBanner}/>
          <Sheet sx={{ display: { sm:"none", xs:"block"} }}>
            <div id='top-header'>
              <div id={systemBanner ? 'top-bar-system-banner' : 'top-bar'}>
                <MobileMenuButton />
                <img src="/assets/logo.png" alt='Logo'/>
              </div>
            </div>
          </Sheet>
          <div id='layout'>
            {visible && <MainLayout/>}
            <div id={visible ? 'main' : 'main-no-auth'}>
              <Outlet/>
            </div>
          </div>
        </div>
    );
}

export default Layout