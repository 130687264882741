// Joyrideconfig.js
import React from "react";
import Joyride from "react-joyride";

export default function Joyrideconfig({
  steps,
  runTour,
  stepIndex,
  handleJoyrideCallback,
}) {
  // All your Joyride configuration
  return (
    <Joyride
      continuous={true}
      showSkipButton={true}
      showProgress={false}
      scrollToFirstStep={true}
      spotlightClicks={true}
      disableBeacon={true}
      disableOverlayClose={true}
      disableCloseOnEsc={true}
      callback={handleJoyrideCallback}
      steps={steps}
      run={runTour}
      stepIndex={stepIndex}
      locale={{
        last: "Finish",
        next: "Next",
        back: "Back",
        skip: "Skip",
        close: "Close",
      }}
      styles={{
        options: {
          arrowColor: "var(--main-blue)",
          backgroundColor: "var(--main-blue)",
          overlayColor: "rgba(0,0,0,0.5)",
          primaryColor: "#ffffff",
          textColor: "#ffffff",
          zIndex: 1000,
        },
        tooltipContainer: {
          fontFamily: "Geist",
          padding: "16px",
          borderRadius: "8px",
          lineHeight: 1.4,
        },
        tooltipTitle: {
          fontFamily: "Geist",
          fontSize: "18px",
          fontWeight: "600",
          marginBottom: "8px",
          color: "var(--light-gray)",
        },
        tooltipContent: {
          fontFamily: "Geist",
          fontSize: "15px",
          color: "var(--light-gray)",
          position: "center",
        },
        buttonNext: {
          fontFamily: "Geist",
          backgroundColor: "var(--dark-blue-button)",
          color: "var(--light-gray)",
          borderRadius: "4px",
          padding: "8px 16px",
          fontSize: "14px",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
          "&:hover": {
            backgroundColor: "var(--dark-blue-button-hover)",
          },
        },
        buttonBack: {
          fontFamily: "Geist",
          backgroundColor: "var(--dark-blue-button)",
          color: "var(--light-gray)",
          borderRadius: "4px",
          padding: "8px 16px",
          fontSize: "14px",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
          "&:hover": {
            backgroundColor: "var(--dark-blue-button-hover)",
          },
        },
        buttonClose: {
          display: "none",
        },
        buttonSkip: {
          position: "absolute", // Keeps it at the top
          top: "8px", // Adjusts its vertical position
          left: "50%", // Moves the button horizontally to the center
          transform: "translateX(-50%)", // Centers the button by adjusting for its width
          padding: "8px 16px",
          borderRadius: "4px",
          color: "var(--light-gray)",
          fontSize: "14px",
          fontFamily: "Geist",
          cursor: "pointer",
        },
      }}
    />
  );
}
