import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, FormControl, IconButton, Typography } from "@mui/joy";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "../styling/ForgotPassword.css";
import { AlertContext } from "../context/AlertFlag";
import axios from "axios";
import FloatingLabelInput from "../components/joy/FloatingInput";


function ForgotPasswordView () {
    const { addAlert } = useContext(AlertContext);
    const navigate = useNavigate();
    const [emailValue, setEmailValue] = useState('');
    
    const handleEmailChange = (event) => {
        setEmailValue(event.target.value);
    };

    const handleSubmit = async () => {
        await axios.post(`${process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_HOST}/api/reset_password`, {
            'email': emailValue,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => {
            addAlert("An email with your temporary password has been sent to your inbox! Redirecting you in 5 seconds...", "success");
            
            setTimeout( () => {
                navigate("/login");
            }, 5000);
        }).catch(error => {
            addAlert("Invalid Email.", "danger")
        })
    }

    const goBack = () => {
        navigate('/');
    }

    return (
        <Box sx={{ display: "flex", flexGrow: 1, height: "100%", justifyContent: "center"}}>
            <Box sx={(theme) => ({
                margin: '25px', borderRadius: '30px', display: "flex", flexDirection: "column", boxShadow: theme.shadow.md, backgroundColor: 'rgb(255, 255, 255, 50%)', [theme.breakpoints.up('sm')]: {
                    width: {xs: '45%', md: "30%"}
                }, [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    alignSelf: 'center',
                }
            })}>
                <IconButton onClick={goBack} sx={{
                        marginTop: '15px',
                        marginLeft: '15px',
                        height: '50px',
                        width: '50px',
                    }}>
                        <ArrowBackIcon />
                </IconButton>
                <Box
                    sx={{display: "flex", justifyContent: "center",  alignItems: "center", width: "100%", mt:"2rem"}}
                >
                    <img src="/assets/logo.png" alt='Logo' style={{maxWidth: "80%"}}/>
                </Box>
                <Box
                    sx={{display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", alignItems: "center", margin: "15px", gap: 3, mb: "20vh"}}
                >
                    <Typography sx={{textAlign: 'left', alignSelf: 'start', fontSize: '1.5rem', fontWeight:"600"}}>Forgot Password?</Typography>
                    <FormControl sx={{ width:"100%", gap: 2}}>
                        <Typography > Enter the email address associated with your account and we'll send you a temporary password to login.</Typography>
                        <FloatingLabelInput 
                            label="Email"
                            value={emailValue}
                            onChange={handleEmailChange}
                        />
                    </FormControl>
                    <Button
                        onClick={handleSubmit}
                        sx={{ fontSize: "1rem", padding: '10px'}}
                    >
                        Continue
                    </Button> 
                </Box>
            </Box>
        </Box>
    );
}

export default ForgotPasswordView