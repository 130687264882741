import React from "react";
import { convertTimeString, grabLocalTime } from "../../utils/TimeConversion";
import { formatNoteName } from "../../utils/FormatNoteNameAndText";
import { ListItem, ListItemButton, ListDivider, Box, Typography, LinearProgress } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { ChevronRight, Timer } from "lucide-react";
import { NoteNameChip } from "./NoteNameChip";

function PastVisitCard({ note }) {
    const navigate = useNavigate();

    const parseTitle = (title) => {
        if (title.length >= 30) {
            return `${title.slice(0, 30)}...`
        } else {
            return title
        }
    }

    const openSelectedVisit = async () => {
      navigate(`${note['id']}`);
    }


    return <>
            <ListItem key={note['id']} sx={{padding: '0px', width: '100%'}}>
                <ListItemButton {...(window.location.href.includes(note['id']) && {
                    selected: true,
                    color: 'neutral',
                })}
                sx={{
                    p: 2, 
                    display: "flex",
                    borderRadius: '0px', 
                    marginTop: '0px', 
                    marginBottom: '0px',
                    transition: "padding-left 0.5s ease",
                    "&:hover": {
                        pl: 6,
                    }, 
                 }} onClick={openSelectedVisit} disabled={!note['generate_note']}>
                    <Box sx={{ pl: 1, width: '100%', backgroundColor: "inherit"}}>
                        <Typography level="title-md" sx={{ mb: 0.5 }}>
                            {parseTitle(note['title'])}
                        </Typography>
                        <Box
                            sx={{
                            display: 'flex',
                            alignItems: "center",
                            gap: 0.5,
                            mb: 0.5,
                            }}
                        >
                            <Timer style={{ width: "15px" }}/>
                            <Typography level="body-xs" textColor="text.tertiary">
                            {grabLocalTime(note['created_at'])}
                            </Typography>
                        </Box>
                        {note['generate_note'] ? <NoteNameChip noteName={note['note_type']} sx={{ backgroundColor: "inherit", m: 0, p: 0 }}/> : <LinearProgress variant='indeterminate' sx={{width:'75%'}}/>}
                    </Box>
                </ListItemButton>
            </ListItem>
        </>
}

export default PastVisitCard