import React from "react"
import { Sheet, Typography } from "@mui/joy"
import { SquareChartGantt } from "lucide-react"



export default function DefaultVisitSelected() {
    return <Sheet sx={{ width: "100%", height: "100vh", alignContent: "center", justifyItems: "center" }}>
        <SquareChartGantt style={{ width: "4em", height: "4em", color: "gray"}}/>
        <Typography level="body-lg" sx={{ color: "gray" }}>
            Select a note or start a new visit.
        </Typography>
    </Sheet>
}