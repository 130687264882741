


export class PDFFormat {
    static pdf_drug_authorization = {
        "PlanMemberSignature": "TextField",
        "Date": "TextField",
        "plan_member_name": "TextField",
        "patient_name": "TextField",
        "plan_name": "TextField",
        "plan_numbe": "TextField", // Misspelled
        "plan_member_id": "TextField",
        "date_of_birth": "TextField",
        "address": "TextField",
        "telephone-contact": "TextField",
        "contact_by_email": "RadioGroup",
        "email_address": "TextField",
        "has_been_on_drug_before": "RadioGroup",
        "drug_start_date": "TextField",
        "drug_coverage_provider": "TextField",
        "has_drug_coverage": "RadioGroup",
        "insurance_company_name": "TextField",
        "plan_id_number": "TextField",
        "name_of_plan_member": "TextField",
        "relationship_to_patient": "TextField",
        "has_provincial_coverage": "RadioGroup",
        "provincial_program_name": "TextField",
        "additional_benefits_plan_details": "TextField",
        "additional_provincial_plan_details": "TextField",
        "receiving_disability_benefits": "RadioGroup",
        "enrolled_in_support_program": "RadioGroup",
        "support_program_id": "TextField",
        "contact_person_name": "TextField",
        "contact_phone_number": "TextField",
        "physician_name": "TextField",
        "physician_specialty": "TextField",
        "physician_address": "TextField",
        "physician_phone": "TextField",
        "physician_fax": "TextField",
        "prescribed_dose": "TextField",
        "patient_weight": "TextField",
        "date_weight_determined": "TextField",
        "medical_condition": "TextField",
        "date_of_diagnosis": "TextField",
        "health_canada_approved": "RadioGroup",
        "anticipated_treatment_duration": "TextField",
        "treatment_administration_location": "RadioGroup",
        "drug_name": "TextField",
        "drug_and/or_treatment_name_1": "TextField",
        "osing_regimen_1": "TextField", // Misspelled,
        "start_date_1": "TextField",
        "end_date_1": "TextField",
        "clinical_results_1": "RadioGroup",
        "clinical_results_details_1": "TextField",
        "drug_and/or_treatment_name_2": "TextField",
        "dosing_regimen_2": "TextField",
        "start_date_2": "TextField",
        "end_date_2": "TextField",
        "clinical_results_2": "RadioGroup",
        "clinical_results_details_2": "TextField",
        "drug_and/or_treatment_name_3": "TextField",
        "dosing_regimen_3": "TextField",
        "start_date_3": "TextField",
        "end_date_3": "TextField",
        "clinical_results_3": "RadioGroup",
        "clinical_results_details_3": "TextField",
        "is_clinical_trial": "RadioGroup",
        "has_diagnostic_testing": "RadioGroup",
        "specialties_and_clinics_1": "TextField",
        "most_recent_assessment_date_1": "TextField",
        "next_assessment_date_1": "TextField",
        "specialties_and_clinics_2": "TextField",
        "most_recent_assessment_date_2": "TextField",
        "next_assessment_date_2": "TextField",
        "specialties_and_clinics_3": "TextField",
        "most_recent_assessment_date_3": "TextField",
        "next_assessment_date_3": "TextField",
        "disease_severity": "TextField",
        "date_disease_determined": "TextField",
        "treatment_rationale": "TextField",
        "treatment_goals": "TextField",
        "start_date_of_treatment": "TextField",
        "clinical_benefit": "TextField",
        "response_description": "TextField",
        "disease_severity_stage": "TextField",
        "response_determination_date": "TextField",
        "renewal_request_additional_details": "TextField",
        "evidence_supporting_off_label_use": "RadioGroup",
        "signature": "TextField",
        "license_number": "TextField",
        "certification_date": "TextField",
    }
}
