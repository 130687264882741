export const copyToClipboard = async (text) => {
    try {
        if (navigator.clipboard && navigator.clipboard.writeText){
            navigator.clipboard.writeText(text)
        } else {
            // Fallback for browsers that don't support navigator.clipboard.writeText()
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
            console.log('Text copied to clipboard (fallback)');
        }
    } catch (error) {
        alert('Error copying to clipboard:', error);
    }
};