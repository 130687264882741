import { useEffect, useState, useCallback } from "react";
import { axiosInstance } from "../../services/Axios";
import { getUserID } from "../../services/Authentication";

export function useFetchUserPreferences() {
    const [ preferences, setUserPreferences ] = useState(null);
    const [ loadingPreferences, setLoading ] = useState(null);
    const [ errorPreferences, setError ] = useState(null);
    const [ reloadFlag, setReloadFlag ] = useState(0);

    const reload = useCallback(() => {
        setReloadFlag((prev) => prev + 1); // Increment the reload flag
    }, []);

    const fetchPreferences = async () => {
        setLoading(true);
        try {
            const response =  await axiosInstance.get(`/api/v1/user/${getUserID()}/preference`);
            setUserPreferences(response.data);
            setError(null);
        } catch(error) {
            setError(error);
            setUserPreferences(null);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchPreferences();
    }, [reloadFlag])

    return { preferences, loadingPreferences, errorPreferences, reload }
}