import { axiosInstance } from "./Axios";

export async function get_user_subscription() {
    try {
        const response = await axiosInstance.get(`/api/v1/subscription`)
        return response.data
    } catch (error) {
        throw new Error(error);
    }
}

export async function patch_subscription() {
    try {
        const response = await axiosInstance.patch(`/api/v1/subscription_paid`)
        return response.data
    } catch (error) {
        throw new Error(error);
    }
}

export async function post_subscription(tier) {
    try {
        const response = await axiosInstance.post(`/api/v1/subscription_paid`, {
            "tier": tier
        })
        const url = response.data.checkout_url
        window.open(url, '_blank');
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
}

export async function delete_subscription() {
    try {
        const response = await axiosInstance.delete(`/api/v1/subscription_paid`)
        return response.data
    } catch (error) {
        throw new Error(error);
    }
}