import React from "react";
import { formatNoteName } from "../../utils/FormatNoteNameAndText";
import { Chip } from "@mui/joy";
import { FileText } from "lucide-react";



export function NoteNameChip({ noteName, ...rest }) {
    return <Chip size="sm" variant="outlined" startDecorator={<FileText style={{ width: '15px', height: '15px' }}/>} {...rest} >
        {formatNoteName(noteName)}
    </Chip>
}