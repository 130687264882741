
export class NoteTypes {
    static developmental_consult = "developmental_consult";
    static medical_assessment = "medical_assessment";
    static developmental_followup = "developmental_followup";
    static adhd_letter = "adhd_letter";
    static referrel_letter_short = "referrel_letter_short";
    static one_month_development_assessment = "one_month_development_assessment";
    static two_month_development_assessment = "two_month_development_assessment";
    static four_month_development_assessment = "four_month_development_assessment";
    static six_month_development_assessment = "six_month_development_assessment";
    static nine_month_development_assessment = "nine_month_development_assessment";
    static twelve_month_development_assessment = "twelve_month_development_assessment";
    static autism_diagnostic_letter = "autism_diagnostic_letter";

    static therapy_note = "therapy_note";
    static therapy_case_note = "therapy_case_note";
    static therapy_intake_assessment_adult = "therapy_intake_assessment_adult";
    static therapy_intake_assessment_child = "therapy_intake_assessment_child";
    static therapy_family_remediation = "therapy_family_remediation";
    static therapy_parenting_plan = "therapy_parenting_plan";
    static therapy_marriage_counselling = "therapy_marriage_counselling";
    static therapy_cbt_dbt = "therapy_cbt_dbt";
    static therapy_supervision_clinical_consult = "therapy_supervision_clinical_consult";

    static radiology = "radiology";

    static emergency_response = "emergency_response";

    static pace_rehab_treatment_note = "pace_rehab_treatment_note";

    static sports_medicine_consult_letter = "sports_medicine_consult_letter";
    static sports_clearance = "sports_clearance";
    static sports_medicine = "sports_medicine";

    static physio = "physio";
    static physio_assessment_letter = "physio_assessment_letter";
    static physio_soap_note = "physio_soap_note";
    static chiropractic = "chiropractic";
    static chiro_referral_letter = "chiro_referral_letter";
    static chiro_sate_note = "chiro_sate_note";
    static chiropractic_soap = "chiropractic_soap";

    static consult_note = "consult_note";
    static consult_note_condensed = "consult_note_condensed";
    static consult_note_multiple_problems = "consult_note_multiple_problems";
    static detailed_soap_note = "detailed_soap_note";
    static discharge_exit_summary = "discharge_exit_summary";
    static patient_handout = "patient_handout";
    static prescription_note = "prescription_note";
    static progress_soap_note = "progress_soap_note";
    static patient_summary = "patient_summary";
    static referral_letter = "referral_letter";
    static specialist_note = "specialist_note";

    static athletic = "athletic";
    static at_pt_soap = "at_pt_soap";
    static at_pt_treatment = "at_pt_treatment";
    static at_pt_assessment = "at_pt_assessment";

    static pedorthist_intake = "pedorthist_intake";

    static geriatric_consult = "geriatric_consult";

    static pdf_drug_authorization = "pdf_drug_authorization";
}

export class NoteSections {
    static pediatric = [NoteTypes.developmental_consult, NoteTypes.medical_assessment, NoteTypes.developmental_followup, NoteTypes.adhd_letter, NoteTypes.referrel_letter_short, NoteTypes.one_month_development_assessment, NoteTypes.two_month_development_assessment, NoteTypes.four_month_development_assessment, NoteTypes.six_month_development_assessment, NoteTypes.nine_month_development_assessment, NoteTypes.twelve_month_development_assessment, NoteTypes.autism_diagnostic_letter]
    static therapy = [NoteTypes.therapy_note, NoteTypes.therapy_case_note, NoteTypes.therapy_intake_assessment_adult, NoteTypes.therapy_intake_assessment_child, NoteTypes.therapy_family_remediation, NoteTypes.therapy_parenting_plan, NoteTypes.therapy_marriage_counselling, NoteTypes.therapy_cbt_dbt, NoteTypes.therapy_supervision_clinical_consult]
    static radiology = [NoteTypes.radiology]
    static emergency_response = [NoteTypes.emergency_response]
    static rehab = [NoteTypes.pace_rehab_treatment_note]
    static sports_medicine= [NoteTypes.sports_medicine_consult_letter, NoteTypes.sports_clearance, NoteTypes.sports_medicine]
    static physio = [NoteTypes.physio, NoteTypes.physio_assessment_letter, NoteTypes.physio_soap_note, NoteTypes.chiropractic, NoteTypes.chiro_referral_letter, NoteTypes.chiro_sate_note, NoteTypes.chiropractic_soap]
    static general_notes = [NoteTypes.consult_note, NoteTypes.consult_note_condensed, NoteTypes.consult_note_multiple_problems, NoteTypes.detailed_soap_note, NoteTypes.discharge_exit_summary, NoteTypes.patient_handout, NoteTypes.prescription_note, NoteTypes.progress_soap_note, NoteTypes.patient_summary, NoteTypes.referral_letter, NoteTypes.specialist_note];
    static athletic = [NoteTypes.athletic, NoteTypes.at_pt_soap, NoteTypes.at_pt_treatment, NoteTypes.at_pt_assessment]
    static pedorthist = [NoteTypes.pedorthist_intake]
    static geriatric = [NoteTypes.geriatric_consult]
    static pdf_forms = [NoteTypes.pdf_drug_authorization]

    static using_note_type(noteType) {
        // Iterate over the class's static properties
        return Object.entries(NoteSections)
            .filter(([sectionName, noteTypes]) => Array.isArray(noteTypes) && noteTypes.includes(noteType))
            .map(([sectionName]) => sectionName); // Return the section names
    }

    // Show em all
    static show_all_note_sections_no_pdf() {
        return Object.entries(NoteSections)
            .map(([sectionName]) => {
                if (sectionName !== "pdf_forms") {
                    return sectionName
                }
            });
    }
}