import { axiosInstance } from "./Axios";

export async function post_visit(presigned_fields, title, note_type, preferences) {
    try {
        const response = await axiosInstance.post('/api/v1/visit', {
            'file_location': presigned_fields['fields']['key'],
            'title': title,
            'split_hpi': preferences['split_hpi'],
            'note_format': preferences['note_format'],
            'note_type': note_type,
            'limit_length': preferences['limit_length'],
        })

        return response.data;
    } catch (error) {
        throw new Error("Unable to create visit");
    }
}

export async function get_visit(visit_id) {
    try {
        const response = await axiosInstance.get(`/api/v1/visit/${visit_id}`);
        return response.data
    } catch(error) {
        throw new Error("Unable to get visit")
    }
}

export async function put_visit(visit_id, request_body) {
    try {
        const response = await axiosInstance.put(`/api/v1/visit/${visit_id}`, request_body);
        return response.data
    } catch(error) {
        throw new Error(error)
    }
}

export async function regenerate_note(visit_id) {
    try {
        const response = await axiosInstance.post(`/api/v1/visit/${visit_id}/regenerate_note`, {
            "transcript_source": 'user_edit',
        })
        return response.data;
    } catch (error) {
        throw new Error(error)
    }
}

export async function delete_visit(visit_id) {
    try {
        const response = await axiosInstance.delete(`/api/v1/visit/${visit_id}`)
        return response.data;
    } catch (error) {
        throw new Error(error)
    }
}