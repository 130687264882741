import React, { useEffect, useState } from "react";
import { Box, Select, List, ListItem, Typography, Option } from "@mui/joy";

import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { capitalizeWords, formatNoteName } from "../../utils/FormatNoteNameAndText";
import { NoteSections } from "../../utils/constants/Notes";


export function NoteTypeSelect({onChange, value, disabled, sx, calmTitle=null, noteViews=null}) {
    const [ sections, setSections ] = useState(null);

    useEffect(() => {
        setSections(noteViews);
    }, [noteViews])

    return <Box sx={{display: "flex", alignItems: "center"}}>
        {!disabled && calmTitle && <h3>Note Type: </h3>}
        {calmTitle && disabled && <Typography> Note Type: </Typography>}
        {sections && <Select placeholder="Note Type" startDecorator={<AppRegistrationIcon />} onChange={onChange} value={value} disabled={disabled} sx={sx}>
            <List>
                {
                    Object.entries(NoteSections).map(([section, notes]) => {
                        return <>
                            {(disabled ? true : sections.includes(section) ? true : false) && <>
                                <ListItem>
                                    <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}> {capitalizeWords(section, "_")} </Typography>
                                </ListItem>
                                {notes.map((note) => {
                                    return <Option key={note} value={note}> {formatNoteName(note)} </Option>
                                })}
                            </>}
                        </>
                    })
                }
                {/* <ListItem>
                    <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}> PDF Forms </Typography>
                </ListItem>
                {NoteSections.pdf_forms.map((note) => {
                    return <Option key={note} value={note}> {formatNoteName(note)} </Option>
                })} */}
            </List>
        </Select>}
    </Box>
}