import React from "react";
import { Modal, ModalDialog, DialogTitle, DialogActions, Button } from "@mui/joy";
import { clearUser } from "../services/Authentication";
import { useNavigate } from "react-router-dom";


const LogOutComponent = () => {
    let navigate = useNavigate();

    const handleLogout = () => {
        clearUser();
        navigate("/login");
    }

    const handleGoBack = () => {
        navigate(-1);
    }


    return <Modal open={true}>
        <ModalDialog>
            <DialogTitle sx={{fontWeight: "normal", textAlign: "center"}}>
                Are you sure you want to logout?
            </DialogTitle>
            <DialogActions>
                <Button 
                    variant="plain"
                    sx={{fontWeight: "normal", color: "black", backgroundColor: "white", border: "2px solid #f1f3f4" }}
                    onClick={() => {
                        handleLogout();
                    }}
                >
                    LOGOUT
                </Button>
                <Button 
                    variant="plain"
                    sx={{fontWeight: "normal", color: "white", '&:hover': { backgroundColor: 'var(--main-blue-hover)', color: 'white'}, '&:active': {backgroundColor: 'var(--light-blue-button)'}}}
                    onClick={() => {
                        handleGoBack()
                    }}
                >
                    CANCEL
                </Button>
            </DialogActions>
        </ModalDialog>
    </Modal>
}

export default LogOutComponent