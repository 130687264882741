export function formatNoteName(string) {
    switch(string) {
        case ("physio"): {
            return "Physiotherapy Note"
        } case ("athletic"): {
            return "Athletic Note"
        } case ("chiropractic"): {
            return "Chiropractic Note"
        } case ("sports_medicine"): {
            return "Sports Medical Note"
        } case ("sports_medicine_consult_letter"): {
            return "Sports Medical Consult Letter"
        } case ("radiology"): {
            return "Radiology"
        } case ("therapy_cbt_dbt"): {
            return "Therapy - CBT / DBT Note"
        } case ("therapy_family_remediation"): {
            return "Therapy - Family Mediation Note"
        } case ("therapy_marriage_counselling"): {
            return "Therapy - Partner Counselling Note"
        } case ("therapy_parenting_plan"): {
            return "Therapy - Parenting Plan Note"
        } case ("emergency_response"): {
            return "Emergency Response"
        } case ("therapy_note"): {
            return "Therapy Note"
        } case ("therapy_case_note"): {
            return "Therapy - Case Note"
        } case ("therapy_intake_assessment_adult"): {
            return "Therapy - Intake Assessment Adult"
        } case ("therapy_intake_assessment_child"): {
            return "Therapy - Intake Assessment Child"

        } default: {
            return capitalizeWords(string, "_")
        }
    }
}

export function formatNoteSections(string) {
    return capitalizeWords(string, "_");
}

export class NoteTypes {
    static developmental_consult = "developmental_consult";
    static medical_assessment = "medical_assessment";
    static developmental_followup = "developmental_followup";
    static adhd_letter = "adhd_letter";
    static referrel_letter_short = "referrel_letter_short";
    static one_month_development_assessment = "one_month_development_assessment";
    static two_month_development_assessment = "two_month_development_assessment";
    static four_month_development_assessment = "four_month_development_assessment";
    static six_month_development_assessment = "six_month_development_assessment";
    static nine_month_development_assessment = "nine_month_development_assessment";
    static twelve_month_development_assessment = "twelve_month_development_assessment";
    static autism_diagnostic_letter = "autism_diagnostic_letter";

    static therapy_note = "therapy_note";
    static therapy_case_note = "therapy_case_note";
    static therapy_intake_assessment_adult = "therapy_intake_assessment_adult";
    static therapy_intake_assessment_child = "therapy_intake_assessment_child";
    static therapy_family_remediation = "therapy_family_remediation";
    static therapy_parenting_plan = "therapy_parenting_plan";
    static therapy_marriage_counselling = "therapy_marriage_counselling";
    static therapy_cbt_dbt = "therapy_cbt_dbt";
    static therapy_supervision_clinical_consult = "therapy_supervision_clinical_consult";

    static radiology = "radiology";

    static emergency_response = "emergency_response";

    static pace_rehab_treatment_note = "pace_rehab_treatment_note";

    static sports_medicine_consult_letter = "sports_medicine_consult_letter";
    static sports_clearance = "sports_clearance";
    static sports_medicine = "sports_medicine";

    static physio = "physio";
    static physio_assessment_letter = "physio_assessment_letter";
    static physio_soap_note = "physio_soap_note";
    static chiropractic = "chiropractic";
    static chiro_referral_letter = "chiro_referral_letter";
    static chiro_sate_note = "chiro_sate_note";
    static chiropractic_soap = "chiropractic_soap";

    static consult_note = "consult_note";
    static consult_note_condensed = "consult_note_condensed";
    static consult_note_multiple_problems = "consult_note_multiple_problems";
    static detailed_soap_note = "detailed_soap_note";
    static discharge_exit_summary = "discharge_exit_summary";
    static patient_handout = "patient_handout";
    static prescription_note = "prescription_note";
    static progress_soap_note = "progress_soap_note";
    static patient_summary = "patient_summary";
    static referral_letter = "referral_letter";
    static specialist_note = "specialist_note";

    static athletic = "athletic";
    static at_pt_soap = "at_pt_soap";
    static at_pt_treatment = "at_pt_treatment";
    static at_pt_assessment = "at_pt_assessment";

    static pedorthist_intake = "pedorthist_intake";

    static geriatric_consult = "geriatric_consult";

    static pdf_sample = "pdf_sample";
}

export class NoteSections {
    static pediatric = [NoteTypes.developmental_consult, NoteTypes.medical_assessment, NoteTypes.developmental_followup, NoteTypes.adhd_letter, NoteTypes.referrel_letter_short, NoteTypes.one_month_development_assessment, NoteTypes.two_month_development_assessment, NoteTypes.four_month_development_assessment, NoteTypes.six_month_development_assessment, NoteTypes.nine_month_development_assessment, NoteTypes.twelve_month_development_assessment, NoteTypes.autism_diagnostic_letter]
    static therapy = [NoteTypes.therapy_note, NoteTypes.therapy_case_note, NoteTypes.therapy_intake_assessment_adult, NoteTypes.therapy_intake_assessment_child, NoteTypes.therapy_family_remediation, NoteTypes.therapy_parenting_plan, NoteTypes.therapy_marriage_counselling, NoteTypes.therapy_cbt_dbt, NoteTypes.therapy_supervision_clinical_consult]
    static radiology = [NoteTypes.radiology]
    static emergency_response = [NoteTypes.emergency_response]
    static rehab = [NoteTypes.pace_rehab_treatment_note]
    static sports_medicine= [NoteTypes.sports_medicine_consult_letter, NoteTypes.sports_clearance, NoteTypes.sports_medicine]
    static physio = [NoteTypes.physio, NoteTypes.physio_assessment_letter, NoteTypes.physio_soap_note, NoteTypes.chiropractic, NoteTypes.chiro_referral_letter, NoteTypes.chiro_sate_note, NoteTypes.chiropractic_soap]
    static general_notes = [NoteTypes.consult_note, NoteTypes.consult_note_condensed, NoteTypes.consult_note_multiple_problems, NoteTypes.detailed_soap_note, NoteTypes.discharge_exit_summary, NoteTypes.patient_handout, NoteTypes.prescription_note, NoteTypes.progress_soap_note, NoteTypes.patient_summary, NoteTypes.referral_letter, NoteTypes.specialist_note];
    static athletic = [NoteTypes.athletic, NoteTypes.at_pt_soap, NoteTypes.at_pt_treatment, NoteTypes.at_pt_assessment]
    static pedorthist = [NoteTypes.pedorthist_intake]
    static geriatric = [NoteTypes.geriatric_consult]
    static pdf_forms = [NoteTypes.pdf_sample]
}


export function capitalizeWords(sentence, factor) {
    const words = sentence.split(factor);
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(' ');
}

export function checkExistingTitles(string) {
    const regex = /\*\*([^*]+)\*\*/g;
    return regex.test(string);
}


export function formatPhoneNumber(phoneString) {
    if (!phoneString) {
        return phoneString;
    }

    let input = phoneString.replace(/\D/g, "");
    let proper_number = "";

    if (input.length >= 10) {
        proper_number += `(${input.substring(0 , 3)})-${input.substring(3 , 6)}-${input.substring(6, 10)}`;
    } else {
        proper_number = input;
    }
    return proper_number
}

export function grabAvatar(full_name) {
    if (full_name.includes(" ")) {
        const split_name = full_name.split(" ");
        return split_name[0].substring(0, 1).toUpperCase() + split_name[1].substring(0, 1).toUpperCase();
    } else {
        return full_name.substring(0, 1).toUpperCase();
    }
}

export function createNoteText(titles, noteBodyReferences) {
    try {
        let noteText = '';
        titles.forEach( (title, index) => {
            index === 0 ? noteText += `**${title}**\n` : noteText += `\n\n**${title}**\n `;
            noteText += noteBodyReferences[index].current.innerText;
        })
        return noteText;
    } catch (error) {
        throw new Error(error);
    }
}