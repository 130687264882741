import React, { useEffect, useState } from 'react';
import '../styling/NavigationBar.css';
import { Typography } from '@mui/joy';
import axios from 'axios';


function SystemBanner (props) {
    const [systemStatus, setSystemStatus] = useState('');
    const [statusInfo, setStatusInfo] = useState('');
    const [display, setDisplay] = useState(false);

    async function getSystemStatus () {
        try {
          const response = await axios.get(`${process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_HOST}/api/system_status`);
          if (response.data['status'] === 'degraded') {
            localStorage.setItem('platformStatus', 'degraded');
            props.setVisible(true);
            setDisplay(true);
            setSystemStatus('degraded');
            setStatusInfo(response.data['additional_info']);
          } else if (localStorage.getItem('platformStatus') === 'degraded' && response.data['status'] === 'operational') {
            localStorage.setItem('platformStatus', null);
            props.setVisible(true);
            setDisplay(true);
            setSystemStatus('operational');
            setStatusInfo(response.data['additional_info']);
          }
        } catch (error) {
          console.log(error);
        }
      }

    useEffect(() => {
        getSystemStatus();
        let occasionallyPoll = setInterval( async () => {
            getSystemStatus();
        }, 180000);

        return( () => {
            clearInterval(occasionallyPoll);
        })
    }, [])

    return <>
        {display && <div class={systemStatus === 'degraded' ? 'system-banner-degraded' : 'system-banner-operational'}>
            <Typography> {statusInfo} </Typography>
        </div>}
    </>
}


export default SystemBanner;