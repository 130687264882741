import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginView from './pages/LoginView';
import DefaultView from './pages/DefaultView';
import SignUpView from './pages/SignupView';
import ProtectedRoute from './services/Authentication';
import AccountPage from './pages/AccountPage';
import SettingsView from './pages/SettingsPage';
import ForgotPasswordView from './pages/ForgotPasswordView';
import ResetPasswordView from './pages/ResetPassword';

import React, { useEffect } from 'react';
import LogOutComponent from './pages/LogOutComponent';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import Layout from './layout/Layout';
import { AlertProvider } from './context/AlertFlag';
import NewVisitPage from './pages/NewVisit';
import PastVisitsNew from './pages/PastVisits';
import { MicrophoneProvider } from './context/MicContext';
import PastVisitsExpanded from './pages/PastVisitExpanded';
import DefaultVisitSelected from './components/joy/NoVisitSelected';

const theme = extendTheme({
  colorSchemes: {
    dark: {
      palette: {
        primary: {
          50: '#EDF5FD',
          100: '#ELE6FF',
          200: '#b2bbe3',
          300: '#a0b0f7',
          400: '#8194e6',
          500: '#7b92f5ff',
          600: '#5774f0',
          700: '#cbd1ecff',
          800: '#2a3041ff',
          900: '#2a3041ff',
          plainColor: '#7b92f5ff',
        },
      }
    },
    light: {
      palette: {
        primary: {
          50: '#EDF5FD',
          100: '#ELE6FF',
          200: '#b2bbe3',
          300: '#a0b0f7',
          400: '#8194e6',
          500: '#7b92f5ff',
          600: '#5774f0',
          700: '#cbd1ecff',
          800: '#2a3041ff',
          900: '#2a3041ff',
          plainColor: '#7b92f5ff',
        },
      }
    }
  },
  typography: {
    fontFamily: 'Inter',
    fontWeight: {
      sm: 200, // Small text font weight
      md: 300, // Medium text font weight
      lg: 400, // Large text font weight
      xl: 400, // Extra large text font weight
    },
    fontSize: {
      sm: '0.875rem', // Small text font size
      md: '1rem', // Medium text font size
      lg: '1.125rem', // Large text font size
      xl: '1.25rem', // Extra large text font size
    },
  },
  components: {
    JoyDialogTitle:{
      styleOverrides: {
        root:{
          fontFamily: 'Inter',
        }
      }
    },
    JoyChip: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
        }
      }
    },
    JoyButton:{
      styleOverrides: {
        root:{
          fontFamily: 'Inter',
          backgroundColor: '#5774f0',
          padding: '10px',
          fontSize: '1rem',
          display: 'inline-block',
        }
      }
    },
    JoyTypography:{
      styleOverrides: {
        root:{
          fontFamily: 'Inter',
          color: 'black',
        }
      }
    },
    JoyInput:{
      styleOverrides: {
        root:{
          fontFamily: 'Inter',
        }
      }
    },
    JoySelect:{
      styleOverrides: {
        root:{
          fontFamily: 'Inter',
          height: "fit-content",
          padding: "10px",
          fontSize: "0.9rem",
        },
      }
    },
    JoyListItemButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          marginTop: '1px',
          marginBottom: '1px',
          paddingTop: '14px',
          paddingBottom: '14px',
          borderRadius: '12px',
        }
      }
    },
    JoyList: {
      styleOverrides: {
        root: {
          flexGrow: 0
        }
      }
    },
    JoyFormLabel:{
      styleOverrides: {
        root:{
          fontFamily: 'Inter',
        }
      }
    },
    JoyFormHelperText:{
      styleOverrides: {
        root:{
          fontFamily: 'Inter',
        }
      }
    },
    JoyLink:{
      styleOverrides: {
        root:{
          fontFamily: 'Inter',
        }
      }
    },
    JoyOption: {
      styleOverrides: {
        root: {
          '&[aria-selected="true"]': {
              backgroundColor: 'var(--main-blue-hover)', // Replace with your desired color
              color: 'white', // Adjust text color if needed
          },
          fontFamily: 'Inter',
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color &&
            ownerState.color !== "inherit" && {
              color: `var(--joy-palette-primary-${ownerState.color})`
            }),
          margin: "var(--Icon-margin)",
          ...(ownerState.fontSize &&
            ownerState.fontSize !== "inherit" && {
              fontSize: `var(--Icon-fontSize, var(--joy-fontSize-${ownerState.fontSize}))`
            }),
          ...(ownerState.instanceFontSize &&
            ownerState.instanceFontSize !== "inherit" && {
              "--Icon-fontSize": `var(--joy-fontSize-${ownerState.instanceFontSize})`
            })
        })
      }
    },
    JoyAlert: {
      styleOverrides: {
        root:{
          fontFamily: 'Inter',
        }
      }
    },
    JoyTooltip: {
      styleOverrides: {
        root:{
          fontFamily: 'Inter',
        }
      }
    },
    JoyRadio: {
      styleOverrides: {
        root:{
          fontFamily: 'Inter',
        }
      }
    }
  }
})
const router = createBrowserRouter([
  { path: '/', element: <Layout/>, children: [
    { index: true, element: <DefaultView />},
    { path: 'login', element: <LoginView /> },
    { path: 'signup/:referralCode?', element: <SignUpView /> },
    { path: 'forgot-password', element: <ForgotPasswordView /> },
    { path: 'reset-password', element: <ProtectedRoute><ResetPasswordView /></ProtectedRoute> },
    { path: 'new-visit', element: <ProtectedRoute> <NewVisitPage/> </ProtectedRoute> },
    { path: 'past-visits', element: <ProtectedRoute>< PastVisitsNew/></ProtectedRoute>, children: [
      { index: true, element: <DefaultVisitSelected />},
      { path: ':visitId', element: <PastVisitsExpanded /> }
    ]},
    { path: 'account', element: <ProtectedRoute><AccountPage /></ProtectedRoute> },
    { path: 'settings', element: <ProtectedRoute><SettingsView /></ProtectedRoute> },
    { path: 'logout', element: <ProtectedRoute><LogOutComponent /></ProtectedRoute> },
  ]},
])

function App() {

  return (
    <CssVarsProvider theme={theme}>
      <AlertProvider>
        <MicrophoneProvider>
          <RouterProvider router={router}/>
        </MicrophoneProvider>
      </AlertProvider>
    </CssVarsProvider>
  );
}

export default App;
