import React from 'react';
import PDFObject from "pdfobject";
import { PDFDocument } from 'pdf-lib';


export async function build_pdf(pdf_url, form_fields, field_inputs) {
    // This grabs it from assets? Should store this in a bucket or something 
    try {
        const pdf_bytes = await fetch(pdf_url).then((res) => res.arrayBuffer());
        const pdf_doc = await PDFDocument.load(pdf_bytes);
        const pdf_form = pdf_doc.getForm();

        // Iterate through and set fields
        const pdf_fields = pdf_form.getFields();
        for (const field of pdf_fields) {
            if (field.constructor.name === "PDFTextField") {
                field.setText("Sample");
            } else if (field.constructor.name === "PDFRadioGroup") {
                field.select(field.getOptions()[0]);
            }
        }

        const modified_pdf_bytes = await pdf_doc.save();
        const pdf_blob = new Blob([modified_pdf_bytes], { type: 'application/pdf' });

        // Step 6: Render the new PDF using PDFObject
        const modified_pdf_url = URL.createObjectURL(pdf_blob);
        return modified_pdf_url;
    } catch (error) {
        throw new Error(error);
    }
}   

export const DocEmbedder = ({ visit }) => {

    const getURL = async () => {
      const url = await build_pdf(`/assets/${visit.note_type}.pdf`, null, null);
      PDFObject.embed(url, "#pdf-viewer");
    }
  
    // Embed the PDF when the component mounts
    React.useEffect(() => {
      getURL()
    }, []);
  
    return (
      <div>
        <div id="pdf-viewer" style={{ width: "100%", height: "600px" }}></div>
      </div>
    );
  };