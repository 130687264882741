import { useEffect, useState, useCallback } from "react";
import { axiosInstance } from "../../services/Axios";
import { getUserID } from "../../services/Authentication";

export function useFetchUser() {
    const [ user, setUser ] = useState(null);
    const [ loadingUser, setLoading ] = useState(null);
    const [ errorUser, setError ] = useState(null);
    const [ reloadFlag, setReloadFlag ] = useState(0);

    const reload = useCallback(() => {
        setReloadFlag((prev) => prev + 1); // Increment the reload flag
    }, []);

    const fetchUser = async () => {
        setLoading(true);
        try {
            const response =  await axiosInstance.get(`/api/v1/user/${getUserID()}`);
            setUser(response.data);
            setError(null);
        } catch(error) {
            setError(error);
            setUser(null);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchUser();
    }, [reloadFlag])

    return {user, loadingUser, errorUser, reload }
}