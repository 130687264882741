import React, { useState, createRef, useContext, forwardRef } from "react";
import { createNoteText, formatNoteName, NoteSections } from "../../utils/FormatNoteNameAndText";
import { getFullName } from "../../services/Authentication";
import { formattedText } from "./DisplayText";
import { AlertContext } from "../../context/AlertFlag";
import { downloadTextoTxt, NoteToPDF } from "../../utils/DownloadNote";
import ConfirmEmailModal from "../modals/ConfirmSendEmail";
import { sendEmail } from "../../services/Email";
import { Box, Typography, Button, Select, Option, Divider } from "@mui/joy";
import { NotepadText, Share, X, Check, Pencil } from "lucide-react";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { copyToClipboard } from "../../utils/Clipboard";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DocEmbedder } from "../embed-pdf/buildpdf";


// Copy full text, save text
export const NoteTextComponent = forwardRef(function NoteTextComponent(
    { visit, lift_editing, lift_save },
    ref
) {
    const { addAlert } = useContext(AlertContext);
    const [editingNote, setEditingNoteFlag] = useState(false);
    const [openConfirmSendEmail, setOpenConfirmSendEmail] = useState(false);

    let titles = [];
    let noteParts = [];
    let noteBodyReferences = [];


    const copyNote = () => {
        copyToClipboard(createNoteText(titles, noteBodyReferences));
        addAlert("Copied note!", "success");
    }

    const chooseText = () => {
        try {
            if (visit.user_edited_result.value.length <= 0) {
                return visit.generate_note.generated_result.value;
            } else {
                return visit.user_edited_result.value;
            }
        } catch (error) {
            addAlert("Something went wrong", "danger");
        }
    };

    const setEditingNote = (value) => {
        setEditingNoteFlag(value);
        lift_editing(value);
    };

    const handleCancelSaveNote = () => {
        for (let index = 0; index < noteBodyReferences.length; index++) {
            if (noteBodyReferences[index].current) {
                noteBodyReferences[index].current.textContent = noteParts[index];
            }
        }
    };

    const handleSaveNote = async () => {
        await lift_save(createNoteText(titles, noteBodyReferences));
    };

    const triggerExport = async (setting) => {
        try {
            switch (setting) {
                case "txt":
                    downloadTextoTxt(chooseText(), visit.title, visit.created_at);
                    break;
                case "pdf":
                    break;
                case "email":
                    setOpenConfirmSendEmail(true);
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const setRefs = (index) => {
        noteBodyReferences[index] = createRef();
    };

    return (
        <>
            {NoteSections.pdf_forms.includes(visit.note_type) ? 
            <Box sx={{ px: 2 }}>
                <DocEmbedder visit={visit} />
            </Box>
            :
            <Box ref={ref}>
                <ConfirmEmailModal
                    open={openConfirmSendEmail}
                    closePopupExternal={() => {
                        setOpenConfirmSendEmail(false);
                    }}
                    sendEmailExternal={async () => {
                        await sendEmail(visit.id);
                        addAlert("Sent email!", "success");
                    }}
                />
                <Box sx={{ display: "flex", justifyContent: "space-between", px: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <NotepadText
                            style={{
                                color: "var(--main-blue)",
                                height: "2.75em",
                                width: "2.75em",
                                paddingRight: 2,
                            }}
                        />
                        <Typography level="title-lg" sx={{ pb: 0.4 }}>
                            {formatNoteName(visit.note_type)}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <Button sx={{ display: "flex", whiteSpace: "nowrap", p: 1, py: 0.2 }} startDecorator={<CopyAllIcon />} onClick={() => copyNote()}>
                            Copy Note
                        </Button>
                        {editingNote && (
                            <Button
                                startDecorator={<X />}
                                sx={{ backgroundColor: "white", color: "gray", display: "flex" }}
                                variant="plain"
                                onClick={() => {
                                    handleCancelSaveNote();
                                    setEditingNote(false);
                                }}
                            >
                                Cancel
                            </Button>
                        )}
                        <Button
                            sx={{ backgroundColor: "white", display: "flex" }}
                            variant="plain"
                            startDecorator={editingNote ? <Check /> : <Pencil />}
                            onClick={async () => {
                                if (editingNote) {
                                    await handleSaveNote();
                                    setEditingNote(false);
                                } else {
                                    setEditingNote(true);
                                }
                            }}
                        >
                            {editingNote ? "Save" : "Edit"}
                        </Button>
                        <Select
                            value="export"
                            variant="plain"
                            sx={{ minWidth: 95, py: 2, color: "var(--dark-blue-button)" }}
                            onChange={(event, value) => {
                                triggerExport(value);
                            }}
                            indicator={null}
                            startDecorator={<Share style={{ height: "20px", width: "20px" }} />}
                        >
                            <Option value="export" disabled sx={{ display: "none" }}>
                                Export
                            </Option>
                            <Option value="email">Email</Option>
                            <Option value="txt">to TXT file</Option>
                            <Option value="pdf">
                                <PDFDownloadLink
                                    document={
                                        <NoteToPDF
                                            title={visit.title}
                                            note={visit}
                                            createdBy={getFullName()}
                                            noteType={visit.note_type}
                                        />
                                    }
                                    fileName={visit.title}
                                >
                                    to PDF file
                                </PDFDownloadLink>
                            </Option>
                        </Select>
                    </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ whiteSpace: "pre-wrap", py: 1, px: 3 }}>
                    {formattedText(
                        chooseText(),
                        (new_titles) => {
                            titles = new_titles;
                        },
                        setRefs,
                        noteBodyReferences,
                        editingNote,
                        (parts) => {
                            noteParts = parts;
                        }
                    )}
                </Box>
            </Box>}
        </>
    );
});
