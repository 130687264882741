import React, { useState } from "react";
import { NoteTypeSelect } from "../custom/NoteTypeSelect";
import { Modal, Sheet, ModalClose, Typography, Button, Box } from "@mui/joy";
import { NoteSections } from "../../utils/constants/Notes";


export function RegenerateNoteTypeModal({ open, closePopup, regenerate, note_default }) {
    const [ note_type, set_note_type ] = useState(note_default);

    const handleSubmit = async (event) => {
        event.preventDefault();

        await regenerate(note_type);
        closePopup();
    }

    return <Modal aria-labelledby="modal-title"
            aria-describedby="modal-desc" open={open} onClose={closePopup} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Sheet variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            display: "flex",
            flexDirection: "column",
          }}>
            <ModalClose variant="plain" sx={{ m: 1, width: "fit-content" }} />
            <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
            >
                Regenerate note
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary" paddingBottom='10px'>
                Choose a new note type if you'd like to, or keep the same one:
            </Typography>
            <form onSubmit={handleSubmit}>
                <NoteTypeSelect disabled={false} calmTitle={false} noteViews={NoteSections.show_all_note_sections_no_pdf()} value={note_type} 
                sx={{
                    width: "100%",
                    mb: 2
                }}
                onChange={(_, note_given) => {
                    set_note_type(note_given)
                }}/>
                <Button type="submit">CONFIRM</Button>
            </form>
        </Sheet>
    </Modal>
}