import React, { useContext, useEffect, useState } from "react";
import { Typography, Button, Select, Option, Box, Tooltip } from "@mui/joy";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { AlertContext } from "../context/AlertFlag.js";
import NavBlocker from "../components/modals/NavBlocker.js";
import { convertTimeString } from "../utils/TimeConversion.js";
import { delete_subscription, get_user_subscription, patch_subscription, post_subscription } from "../services/Subscription.js";
import { getVisitCapacity } from "../services/VisitCapacity.js";


function BillingView() {
    const { addAlert } = useContext(AlertContext)
    const [ tier, setTier] = useState('');
    const [ initialTier, setInitialTier ] = useState('');
    const [ billingDate, setBillingDate ] = useState('');
    const [ isCancelled, setIsCancelled ] = useState(false);
    const [ isChangingPlan, setIsChangingPlan ] = useState(false);
    const [ userCapacity, setUserCapacity ] = useState(null);
    const [ userCurrent, setUserCurrent ] = useState(null);
    const [ nextCreateTime, setNextCreateTime ] = useState(null);

    const [ dirty, setDirty] = useState(false);

    const getUserSubscription = async () => {
        try {
            const response = await get_user_subscription();
            setTier(response.tier);
            setInitialTier(response.tier);
            if (response.end_of_billing_period) {
                setBillingDate(convertTimeString(response.end_of_billing_period));
            }
            setIsCancelled(response.is_cancelled_at_period_end);
        } catch (error) {
            addAlert(error.message, "danger")
        }
    }

    const getUserCapacity = async () => {
        try {
            const response = await getVisitCapacity();
            setUserCapacity(response.max_count);
            setUserCurrent(response.created_count);
            setNextCreateTime(convertTimeString(response.next_visit_create_time));
        } catch (error) {
            addAlert(error.message, "danger");
        }
    }

    useEffect(() => {
        getUserSubscription();
        getUserCapacity();
    }, [])

    useEffect(() => {
        if (tier !== initialTier) { 
            setDirty(true);
        } else {
            setDirty(false);
        }
    }, [tier, initialTier]);

    const handleTierChange = (event, newValue) => {
        setTier(newValue);
    }

    const handleChangePlan = async () => {
        try {
            setIsChangingPlan(true);
            if (isCancelled) {
                await patch_subscription();
                setIsCancelled(false);
                addAlert("Plan saved!", "success")
            } else if (tier !== "free") {
                await post_subscription(tier);
            } else {
                const response = await delete_subscription();
                const message = response.data.message;
                if (message === "Stripe subscription will be cancelled at end of billing period") {
                    setIsCancelled(true);
                    setTier("premium")
                    addAlert("Plan saved!", "success");
                }
            }
        } catch(error) {
            addAlert("There was a problem changing your plan", "danger")
        } finally {
            setIsChangingPlan(false);
        }
    }

    return (
        <Box id="outline-it" sx={{
            display: "flex", flexDirection: "column", gap: 2, border: "1px solid #ccc"
        }}>
            <NavBlocker dirty={dirty} />
            <Typography level='h2'>Billing</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', lg: 'row'},
                    justifyContent: 'space-between',
                    gap: 2,
                    px: "1rem",
                }}
            >
                <Box
                    sx={{display: 'flex', alignItems: 'start', gap: "1rem", justifyContent: 'space-between', flexDirection: {xs: 'column', lg: 'row'}}}
                >
                    <Box>
                        <Typography sx={{ alignSelf: "start"}}> Current Plan: </Typography>
                    </Box>
                    <Box 
                        sx={{ 
                            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1, width: {xs: '100%', lg: '20rem'} 
                        }}
                    >
                        <Select
                            value={tier}
                            onChange={handleTierChange}
                            sx={{
                                width: "100%",
                            }}
                        >
                            {!isCancelled &&<Option value="free">Free</Option>}
                            <Option value="premium">Premium</Option>
                        </Select>
                        <Button 
                            disabled={!isCancelled && (initialTier === tier || isChangingPlan)} 
                            onClick={handleChangePlan} 
                            id="upgrade-downgrade" 
                        > 
                            {isCancelled ? "RENEW" : tier !== initialTier && initialTier === "premium" ? "DOWNGRADE": "UPGRADE"} 
                        </Button>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'column',
                }}>
                    <Box sx={{
                        display: 'flex', justifyContent: {xs: 'center', lg: 'end'}, width: '100%', gap: "0.5rem", alignItems: 'center'
                    }}>
                        {userCurrent === userCapacity && <Tooltip title={`You've maxed out your current plan. Please wait until ${nextCreateTime} to generate a new note, or upgrade to premium`}> 
                            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                                <HelpOutlineIcon/>
                            </Box>
                        </Tooltip>}
                        <Typography sx={{
                            alignSelf: {xs: 'center', lg: 'end'},
                            textAlign: 'center'
                        }}>
                            {userCurrent === userCapacity ? 'Notes Maxed:' : 'Notes Available:'} {userCurrent} / {userCapacity}
                        </Typography>
                    </Box>
                    {userCurrent === userCapacity && <Typography sx={{
                        alignSelf: {xs: 'center', lg: 'end'},
                        textAlign: 'center'
                    }}>
                        Notes Limit Resets On:  {nextCreateTime}
                    </Typography>}
                    {initialTier !== 'free' && <Typography sx={{
                        alignSelf: {xs: 'center', lg: 'end'},
                        textAlign: 'center'
                    }}>
                        {isCancelled ? 'Your plan is set to cancel on: ' : 'Next Billing Date: '} {billingDate}
                    </Typography>}
                </Box>
            </Box>
        </Box>
    )
}

export default BillingView;