import React, { useState, useCallback, useRef } from "react"
import { Box, Button, IconButton, Modal, ModalClose, Sheet, Typography } from "@mui/joy";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ClearIcon from '@mui/icons-material/Clear';
import "../../styling/CurrentVisit.css";

function UploadTranscriptPopup (props) {

    const [dragging, setDragging] = useState(false);
    const [file, setFile] = useState(null);
    const fileRef = useRef();

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        setDragging(true);
    }, []);

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        setDragging(false);
    }, []);

    const checkFile = (file) => {
        if (!file.type.startsWith("audio/")) { // 20 seconds of bytes or something now
            alert("Please only upload audio files.");
            return false;
        } else if (file.size < 100000) {
            alert("Recording too short!");
            return false;
        }

        return true;
    }

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            checkFile(e.dataTransfer.files[0]) && setFile(e.dataTransfer.files[0]);
        }
    }, []);

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            checkFile(e.target.files[0]) && setFile(e.target.files[0]);
        }
    } 

    const removeFile = () => {
        if (fileRef.current) {
            fileRef.current.value = null;
        }
        setFile(null)
    }

    const closePopup = () => {
        setFile(null)
        props.closePopup()
    }

    const sendAudio = () => {
        if (!file) {
            alert("Please upload an audio first!");
        } else {
            props.audioUploaded(file);
            props.closePopup();
        }
    }

    return <Modal aria-labelledby="modal-title"
    aria-describedby="modal-desc" open={props.open} onClose={closePopup} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Sheet variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            display: "flex",
            flexDirection: "column"
          }}>
            <ModalClose variant="plain" sx={{ m: 1, width: "fit-content" }} />
            <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
            >
                Upload Audio
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary">
            Upload the audio for the visit and press "COMPLETE" to begin:
            </Typography>
            <div className={`dropzone ${dragging ? 'dragging' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}>
                <Box sx={{display: "flex", justifyContent: "center" }}>
                    <input type="file" style={{ display: 'none' }} accept="audio/*" onChange={handleFileChange} id="file-input" ref={fileRef}/>
                    <label htmlFor="file-input" style={{ alignSelf: "center" }}>
                        {file ? file.name : "Drag and drop a file here or click to select a file."}
                    </label>
                    {file && <IconButton onClick={() => removeFile()} sx={{ width: "fit-content"}}> <ClearIcon/></IconButton>}
                </Box>
            </div>
            <Button onClick={sendAudio} endDecorator={<FileUploadIcon />} sx={{ marginTop: "10px", width: "80%", alignSelf: "center", display: "inline-flex"}}> COMPLETE</Button>
        </Sheet>
    </Modal>
}

export default UploadTranscriptPopup