// src/components/custom/useNewVisitTour.js
import { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useState, useCallback, useEffect } from "react";
import { axiosInstance } from "../services/Axios";
import { Box, Typography} from "@mui/joy";


/**
 * This custom hook encapsulates all the logic related to the Joyride steps,
 * callbacks, and side-effects regarding the user's "tour" status.
 *
 * @param {object} user    The user object (so we can check or update `toured`)
 * @returns {object}       An object containing everything needed for Joyride.
 */
export function useNewVisitTour({ user, loadingUser, currentTime, title, debouncedTitle,setDebouncedTitle }){
  // ========== State for Joyride ==========
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([
    {
        target: "body",
        disableOverlay: false,
        disableOverlayClose: true,
        content: (
        <div style={{ textAlign: "center" }}>
            <Typography
                variant="h5"
                style={{ fontWeight: "600", color: "var(--light-gray)" }}
            >
                Welcome to Your Guided Tour!
            </Typography>
            <Typography
                variant="body1"
                style={{ marginTop: "16px", color: "var(--light-gray)" }}
            >
                This step-by-step walkthrough will help you understand how to create and manage patient visit notes effortlessly.
            </Typography>
            <Typography
                variant="body1"
                style={{ marginTop: "16px", color: "var(--light-gray)" }}
            >
                Click "Next" to start exploring the features and streamline your workflow.
            </Typography>
        </div>

        ),
        placement: "center",
        styles: {
            buttonBack: {
                display: "none",
            },
            overlay: {
                pointerEvents: "auto", // Ensure clicks are intercepted by overlay
            },
        },
        showSkipButton: true,
    },
    {
        target: ".tour-patient-name",
        content: "Step 1: Enter the patient's full name in the provided field.",
        styles: {
            buttonNext: {
                display: "none",
            },
        },
        disableBeacon: true,
        showSkipButton: true,
        disableOverlay: false,
        disableOverlayClose: true,
    },
    {
        target: ".tour-visit-type",
        content: "Step 2: Select the type of visit from the dropdown menu.",
        placement: "top",
        disableOverlay: false,
        disableOverlayClose: true,
        showSkipButton: true,
    },
    // {
    //     target: ".tour-pronouns",
    //     content: "Step 3: Choose the patient's preferred pronouns from the dropdown options.",
    //     placement: "top",
    //     disableOverlay: false,
    //     styles: {
    //         buttonNext: {
    //             display: "none",
    //         },
    //     },
    //     disableOverlayClose: true,
    // },
    {
        target: ".tour-start-capturing",
        content: "Step 3: Click 'Start Capturing' to begin recording audio for the visit.",
        styles: {
            buttonNext: {
                display: "none",
            },
            buttonBack: {
                display: "none",
            },
        },
        showSkipButton: true,
        disableOverlay: false,
        disableOverlayClose: true,
    },
    {
        target: "body",
        disableOverlay: false,
        disableOverlayClose: true,
        content: (
<Box sx={{ textAlign: "center", maxHeight: "400px", overflow: "auto" }}>
    <Typography
        variant="h6"
        style={{
            fontWeight: "600",
            color: "var(--light-gray)",
            marginBottom: "16px", // Add spacing below the heading
        }}
    >
        Step 5: Please read this text aloud:
    </Typography>
    <Typography
        variant="body1"
        style={{
            color: "white",
            backgroundColor: "var(--primary-blue)", // Maintain strong contrast
            lineHeight: "1.8", // Increase line spacing for readability
            fontSize: "16px",
            textAlign: "left",
            marginTop: "16px", // Add spacing above the dialogue block
        }}
    >
        <strong>Doctor:</strong> Good morning. What brings you in today?<br />
        <strong>Patient:</strong> I’ve had lower back pain for two weeks after lifting a heavy box.<br />
        <strong>Doctor:</strong> How bad is the pain, and what worsens it?<br />
        <strong>Patient:</strong> About 6 out of 10. Sitting too long or bending forward makes it worse.<br />
        <strong>Doctor:</strong> Any numbness, tingling, or leg weakness?<br />
        <strong>Patient:</strong> No.<br />
        <strong>Doctor:</strong> How are you managing it?<br />
        <strong>Patient:</strong> Ibuprofen and a heating pad, which help a bit.<br />
        <strong>Doctor:</strong> Continue the ibuprofen and try light stretching. I notice your range of motion is restricted. If it doesn’t improve, we might consider an X-ray. Let’s schedule a follow-up in two weeks.<br />
        <strong>Patient:</strong> Got it, thank you.
    </Typography>
</Box>

        ),
        placement: "center",
        styles: {
            buttonNext: {
                display: "none",
            },
            buttonBack: {
                display: "none",
            },
            overlay: {
                pointerEvents: "auto", // Ensure clicks are intercepted by overlay
            },
        },
        showSkipButton: true,
    },
    {
        target: ".tour-pause-audio",
        content: "Step 5: Use this button to pause or resume recording as needed. When you're done, you can stop the recording.",
        disableInteraction: true,
        disableOverlay: false,
        disableOverlayClose: true,
        showSkipButton: true,
    },
    {
        target: ".tour-complete",
        content: "Step 6: Click 'Complete' to finish the recording and process the note.",
        disableInteraction: true,
        disableOverlay: false,
        disableOverlayClose: true,
        showSkipButton: true,
        styles: {
            buttonNext: {
                display: "none", // Initially hide the Next button
            },
        },
    },
    {
        target: "body",
        content: (
            <div style={{ textAlign: "center" }}>
                <Typography
                    variant="h6"
                    style={{ fontWeight: "600", color: "var(--light-gray)" }}
                >
                    Step 7: Your notes are being generated. Please wait.
                </Typography>
                <Typography
                    variant="body1"
                    style={{ color: "var(--light-gray)" }}
                >
                    Once it's ready, you will automatically be redirected to the 'Past Visits' page, where you can view, edit, copy, or share the note as needed.
                </Typography>
            </div>
        ),
        placement: "center",
        disableBeacon: true,
        disableOverlay: false,
        disableOverlayClose: true,
        styles: {
            buttonBack: {
                display: "none"
            },
        }
    },
]);

  // ========== Callback for Joyride ==========
  const handleJoyrideCallback = useCallback(
    (data) => {
      const { status, index, action, type } = data;

      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        setRunTour(false);
        setStepIndex(0);

        // If the user isn't toured, call API to mark user as toured
        if (user && !user.toured) {
          axiosInstance
            .put(`/api/v1/user/${user.id}`, { toured: true })
            .catch((error) => {
              console.error("Failed to update user's toured status:", error);
            });
        }
        return;
      }

      if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
        let nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        setStepIndex(nextStepIndex);
      }
    },
    [user]
  );

  // ========== Additional Effects/Logic ==========
  // For example, automatically start the tour if user has not toured:
  useEffect(() => {
    if (user && !user.toured) {
      setRunTour(true);
    }
  }, [user]);
  useEffect(() => {
       
    //Check if the current step is your "body" step
    if (runTour && steps[stepIndex]?.target === "body") {
      // If we've hit 20 seconds, show the Next button
      if (currentTime >= 20) {
        setSteps((prevSteps) => {
          
          const newSteps = [...prevSteps];
          const currentStep = newSteps[stepIndex];
  
       
          newSteps[stepIndex] = {
            ...currentStep,
            styles: {
              ...currentStep.styles,
              buttonNext: {},
            },
            showSkipButton: false 
          };
          return newSteps;
        });
      }
    }
  }, [currentTime, runTour, stepIndex, steps]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (title.trim()) {
        // Debounce when there is input
        setDebouncedTitle(title);
      }
    }, 100);
  
   
    if (!title.trim()) {
      setDebouncedTitle(""); 
      clearTimeout(handler);
    }
  
    return () => {
      clearTimeout(handler); 
    };
  }, [title]);
  
  // Use debounced title for tour logic
  useEffect(() => {
    if (debouncedTitle && runTour && steps[stepIndex]?.target === ".tour-patient-name") {
      // Enable the Next button when there's input
      setSteps((prevSteps) =>
        prevSteps.map((step, index) =>
          index === stepIndex
            ? { ...step, styles: { ...step.styles, buttonNext: {} } }
            : step
        )
      );
    } else if (runTour && steps[stepIndex]?.target === ".tour-patient-name") {
      // Disable the Next button when input is cleared
      setSteps((prevSteps) =>
        prevSteps.map((step, index) =>
          index === stepIndex
            ? {
                ...step,
                styles: {
                  ...step.styles,
                  buttonNext: {
                    display: "none",
                  },
                },
              }
            : step
        )
      );
    }
  }, [debouncedTitle, runTour, stepIndex, steps]);

  useEffect(() => {
    // Start the tour automatically if the user has not toured before
    if (user && !loadingUser && !user.toured) {
        setRunTour(true);
    }
}, [user, loadingUser]);

useEffect(() => {
    // Add event listener to ".tour-visit-type" element
    if (runTour && steps[stepIndex]?.target === ".tour-visit-type") {
        const element = document.querySelector(".tour-visit-type");
        const handleClick = () => {
            setSteps((prevSteps) =>
                prevSteps.map((step, index) =>
                    index === stepIndex
                        ? { ...step, disableOverlay: true } // Switch to true on click
                        : step
                )
            );
        };

        element?.addEventListener("click", handleClick);

        return () => {
            element?.removeEventListener("click", handleClick); // Cleanup
        };
    }
}, [runTour, stepIndex, steps]);


useEffect(() => {
    // Add event listener to ".tour-pronouns" element
    if (runTour && steps[stepIndex]?.target === ".tour-pronouns") {
        const element = document.querySelector(".tour-pronouns");
        const handleClick = () => {
            setSteps((prevSteps) =>
                prevSteps.map((step, index) =>
                    index === stepIndex
                        ? { ...step, disableOverlay: true } // Switch to true on click
                        : step
                )
            );
        };

        element?.addEventListener("click", handleClick);

        return () => {
            element?.removeEventListener("click", handleClick); // Cleanup
        };
    }
}, [runTour, stepIndex, steps]);

  // Return all the state & the Joyride component itself (or just the data)
  return {
    runTour,
    setRunTour,
    stepIndex,
    setStepIndex,
    steps,
    setSteps,
    handleJoyrideCallback,
  };
}
