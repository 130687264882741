import { axiosInstance } from "./Axios";
import { getUserID } from "./Authentication";

export async function put_user_preferences(split_hpi, note_format, note_type, note_views, input_language, output_language) {
    try {
        const response = await axiosInstance.put(`/api/v1/user/${getUserID()}/preference`, {
            "split_hpi": split_hpi,
            "note_format": note_format,
            "note_type": note_type,
            "note_views": note_views,
            "input_language": input_language,
            "output_language": output_language,
        })
        return response.data
    } catch (error) {
        throw new Error(error);
    }
}