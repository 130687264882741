import React, { createContext, useState, useEffect } from 'react';

const MicrophoneContext = createContext();

export const MicrophoneProvider = ({ children }) => {
  const [selectedMic, setSelectedMic] = useState(null);

  return (
    <MicrophoneContext.Provider value={{ selectedMic, setSelectedMic }}>
      {children}
    </MicrophoneContext.Provider>
  );
};

export default MicrophoneContext;
