import React, { useState, createContext } from "react";
import { Alert , IconButton, Snackbar} from "@mui/joy";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ClearIcon from '@mui/icons-material/Clear';

// Create the AlertContext
export const AlertContext = createContext();

// Create the AlertProvider component
export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);
  let alertsLength = 0;


  const addAlert = async (message, severity) => {
    if(alerts.length > 10) {
        removeAlert(0);
    }
    alertsLength ++;
    setAlerts((prevAlerts) => [...prevAlerts, { message, severity }]);
    setTimeout( () => {
      try{
        removeAlert(alertsLength - 1);
      } catch(error) {
      }
    }, 5000);
  };

  const removeAlert = (index) => {
    alertsLength --;
    setAlerts((prevAlerts) => prevAlerts.filter((_, i) => i !== index));
  };

  const handleIcon = (severity) => {
    if (severity === "danger") {
        return <ErrorOutlineIcon fontSize="xl" />
    } else {
        return <CheckIcon fontSize="xl"/>
    }
  }

  return (
    <AlertContext.Provider value={{ addAlert, alerts }}>
      {children}
      <Snackbar
        open={alerts.length > 0}
        onClose={(event, reason) => {
          if (reason === 'clickaway') return;
          removeAlert(0);
        }}
        variant="plain" anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ padding: "0px", minWidth: "0px", display: "flex", background: "transparent", flexDirection: "column", boxShadow: "unset", alignItems: "end" }}
      >
        {alerts.map((alert, index) => (
          <Alert
            key={index}
            startDecorator={handleIcon(alert.severity)}
            color={alert.severity}
            endDecorator={<IconButton onClick={() => removeAlert(index)}> <ClearIcon fontSize="md"/></IconButton>}
            variant="outlined"
            sx={{ boxShadow: "var(--joy-shadow-md)"}}
          >
            {alert.message}
          </Alert>
        ))}
      </Snackbar>
    </AlertContext.Provider>
  );
};