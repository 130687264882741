// Not actually the main layout lmao, it's the navbar
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Sheet, List, ListItem, ListItemButton, ListItemDecorator, ListItemContent, AspectRatio, Option, CircularProgress, Stack, Typography, Divider, Button, Box, Avatar, Select } from '@mui/joy';
import { selectClasses } from '@mui/joy/Select';
import MicIcon from '@mui/icons-material/Mic';
import HistoryIcon from '@mui/icons-material/History';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpComponent from './HelpElement';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


import '../styling/NavigationBar.css';
import { useFetchVisits } from '../hooks/api/Visits';
import { convertTimeString } from '../utils/TimeConversion';
import { grabAvatar } from '../utils/FormatNoteNameAndText';
import { AlertContext } from '../context/AlertFlag';
import { useFetchUser } from '../hooks/api/User';

function MainLayout() {
  const { alerts } = useContext(AlertContext)
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loadingUser } = useFetchUser();
  const { visits, visitsLoading, reload } = useFetchVisits('1 year', 1, 4);
  const [ accountOptions, setAccountOptions ] = useState(false);

  const isActive = (path) => {
    return location.pathname === path;
  }

  const triggerAccountOptions = (value) => {
    switch(value){
      case "sign_out": {
        navigate("/logout");
        break;
      }
    }
  }

  useEffect(() => {
    if (alerts[alerts.length - 1]?.message.includes("Visit")) {
      reload();
    }
  }, [ alerts ])

  return (
    <div id="nav">
      <Sheet sx={{ display: { xs:"none", sm:"flex"}, flexDirection: "column", flexGrow: 1, borderRight: "1px solid var(--joy-palette-divider)", width: "300px", overflowY: "scroll"}}>
        <AspectRatio objectFit='contain' ratio="8/3" sx={{ padding: '5px', paddingLeft: "10px", paddingRight: "10px", backgroundColor:'white' }} variant='plain'>
          <img src="/assets/backgroundlogo.png" alt='Logo'/>
        </AspectRatio>
        <List component="nav" sx={{ backgroundColor: 'white', padding: '10px' }}>
          <ListItem>
            <ListItemButton component={NavLink} to="/new-visit" sx={{ backgroundColor: isActive('/new-visit') ? '#f1f3f4' : 'inherit' }}>
              <ListItemDecorator> <MicIcon color={isActive('/new-visit') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
              <ListItemContent sx={{ fontSize: '13pt', fontWeight: isActive('/new-visit') ? '600' : 'inherit' }}> New Visit </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton component={NavLink} to="/past-visits" sx={{ backgroundColor: isActive('/past-visits') ? '#f1f3f4' : 'inherit' }}>
              <ListItemDecorator> <HistoryIcon color={isActive('/past-visits') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
              <ListItemContent sx={{ fontSize: '13pt', fontWeight: isActive('/past-visits') ? '600' : 'inherit' }}> Past Visits </ListItemContent>
            </ListItemButton>
          </ListItem>
          {user && !loadingUser && <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              component={NavLink}
              to={"/account"}
              variant="plain"
              sx={{ 
                display: "flex", 
                justifyContent: "start",
                justifyItems: "left",
                backgroundColor: "white", 
                transition: "gap 0.5s ease, padding-left 0.5s ease",
                gap: 1,
                "&:hover": {
                  gap: 3,
                  pl: 2,
                }, 
              }}>
              <Avatar>
                {grabAvatar(user.full_name)}
              </Avatar>
              <Box>
                <Typography level="title-sm"> {user.full_name} </Typography>
                <Typography level="body-xs"> {user.email} </Typography>
              </Box>
            </Button>
            <Select variant="plain" value={null} listboxOpen={accountOptions} onClick={() => {setAccountOptions(!accountOptions)}} sx={{ maxWidth: "fit-content", backgroundColor: "white", "&:hover": { backgroundColor: "white" }, 
              [`& .${selectClasses.indicator}`]: {
                transition: '0.2s',
                [`&.${selectClasses.expanded}`]: {
                  transform: 'rotate(-180deg)',
                },
              },
            }} indicator={<ExpandMoreIcon/>} 

            onChange={(event, value) => {
              triggerAccountOptions(value);
            }}>
              <Option value="sign_out"> Sign Out </Option>
            </Select>
          </Box>
          }
          {!user && loadingUser && <CircularProgress />}
          <Stack sx={{ px: "1em" }}>
              <Typography level="body-xs">
                Most recent visits
              </Typography>
              <Divider sx={{ width: "110%", alignSelf: "center"}}/>
              {visits && !visitsLoading && visits.map((item, index) => {
                return <Button 
                    component={NavLink}
                    disabled={!item['generate_note']}
                    to={`/past-visits/${item["id"]}`}
                    sx={{ 
                      justifyItems: "left", 
                      backgroundColor: "white", 
                      opacity: item['generate_note'] ? 1 : 0.5,
                      px: 0, 
                      color: "currentColor",
                      "&:hover": {
                        ".content-box": {
                            pl: 1.5,
                            gap: 3, // Increase gap on hover
                          },
                      }, 
                    }} variant="plain">
                  <Box className="content-box" sx={{ transition: "gap 0.5s ease, padding-left 0.5s ease", display: "flex",  gap: 1, alignItems: "center"}}>
                    <ArrowForwardIosIcon fontSize="small" sx={{ color: "gray"}} />
                    <Box sx={{ justifyItems: "left" }}>
                      <Typography level="body-sm">
                        {item['title']}
                      </Typography>
                      <Typography level="body-xs">
                        {convertTimeString(item['created_at'])}
                      </Typography>
                    </Box>
                  </Box>
                </Button>
              })}
              {!visits && visitsLoading && <Box sx={{ display: "flex", alignItems: "center"}}>
                <CircularProgress />
              </Box>}
          </Stack>

        </List>
        <HelpComponent/>
      </Sheet>
    </div>
  );
}

export default MainLayout;